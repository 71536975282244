// config.ts

interface AppConfig {
  basePath: any;
  reduxSecretKey: any;
  clientID: any;
  authority: any;
  redirectURI: any;
  siteId: any;
  graphEndPoint: any;
  apiEndPoint:any;
  clientIDPP:any;
  redirectURIPP:any;
}
const appConfig: AppConfig = {
  basePath: process.env.REACT_APP_BASE_PATH,
  reduxSecretKey: process.env.REACT_APP_REDUX_KEY,
  clientID:process.env.REACT_APP_CLIENT_ID,
  authority:process.env.REACT_APP_AUTHORITY,
  redirectURI:process.env.REACT_APP_REDIRECT_URI,
  siteId: process.env.REACT_APP_SITE_ID,
  graphEndPoint: process.env.REACT_APP_GRAPH_END_POINT,
  apiEndPoint: process.env.REACT_APP_API_END_POINT,
  clientIDPP:process.env.REACT_APP_CLIENT_ID_PRACTICE_PANTHER,
  redirectURIPP:process.env.REACT_APP_REDIRECT_URI_PRACTICE_PANTHER
};
  
  export default appConfig;