import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STEPS_DEFAULT_SORT_COLUMN, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';



const initialState = {
    stepsData: [],
    loading: false,
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STEPS_LIST_ROWS_DEF,
        sortFieldProp: STEPS_DEFAULT_SORT_COLUMN,
        caseTypeFilterProp: "",
        deletedStepsProp: false,
    }


};


export const getstepsListAction = createAsyncThunk(
    "knowledgebaseModule/getstepsListAction",
    async (value: any) => {

        const apiUrl = value.searchKey === "" ?
            `${EndPoint.STEPS_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter}&filtereByDeletedStep=${value.deletedSteps}` :
            `${EndPoint.STEPS_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter}&filtereByDeletedStep=${value.deletedSteps}`;
        
        
        
            try {
                const response = await axiosRequest.get(apiUrl);
   
            return response;
        } catch (error: any) {
            return error;

        }
    }
);








export const addNewStepAction = createAsyncThunk(
    "knowledgebaseModule/addNewRoleAction",
    async (value: any) => {

        const addData = {
            step_name: value.stepName,
            required_days: value.stepDays,
            description: value.stepDiscription

        }
        try {

            const response = await axiosRequest.post(`${EndPoint.STEPS_ADD_API}`, addData);
            return response;
            
        } catch (error: any) {

            return error;

        }
    }
);




export const deleteStepAction = createAsyncThunk(
    "knowledgebaseModule/deleteStepAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.STEPS_DELETE_API}/${value.stepId}`);
            return response;
        } catch (error: any) {
            return error;

        }
    }
);




export const updateStepAction = createAsyncThunk(
    "knowledgebaseModule/updateStepAction",
    async (value: any) => {

        const editData = {
            id: value.stepId,
            step_name: value.stepName,
            required_days: value.stepDays,
            description: value.stepDiscription

        }

    
        try {

            const response = await axiosRequest.put(`${EndPoint.STEPS_UPDATE_API}`, editData);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);

export const getStepByIDAction = createAsyncThunk(
    "knowledgebaseModule/getStepByIDAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`knowledge-base-steps/detail/${value.stepId}`);
            return response;
            
        } catch (error: any) {

            return error;

        }
    }
);

/**
 * Getting all common Stpes
 *
 * @type {*}
 */
export const getAllCommonStepsAction = createAsyncThunk(
    "knowledgebaseModule/getAllCommonStepsAction",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.STEPS_GET_API_COMMON}`);
          
            return response;
        } catch (error:any) {

            return error;
        }
    }
);

//EXPORT STEPS
export const exportStepsAction = createAsyncThunk(
    "teamsModule/exportStepsAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.STEPS_EXPORT}?sort=${value.sortField}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter}&filtereByDeletedStep=${value.deletedSteps}` :
        `${EndPoint.STEPS_EXPORT}?sort=${value.sortField}&search=${value.searchKey}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter}&filtereByDeletedStep=${value.deletedSteps}`
        try {

            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {

            return error;

        }
    }
);

// RESTORE STEP
export const restoreStepAction = createAsyncThunk(
    "knowledgebaseModule/restoreStepAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.STEPS_RESTORE_API}`, value);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);

const stepsSlice = createSlice({
    name: 'Knowledge Base Steps',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
            state.listObj.caseTypeFilterProp=actions.payload.caseTypeFilter;
            state.listObj.deletedStepsProp=actions.payload.deletedSteps;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getstepsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getstepsListAction.fulfilled, (state, action) => {

                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.stepsData = data;
            })

            .addCase(getstepsListAction.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(addNewStepAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewStepAction.fulfilled, (state, action) => {

                state.loading = false;
            })

            .addCase(addNewStepAction.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(deleteStepAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteStepAction.fulfilled, (state, action) => {
                state.loading = false;

            })

            .addCase(deleteStepAction.rejected, (state, action) => {
                state.loading = false;
            })


            .addCase(updateStepAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateStepAction.fulfilled, (state, action) => {

                state.loading = false;
            })

            .addCase(updateStepAction.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(exportStepsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(exportStepsAction.fulfilled, (state, action) => {

                state.loading = false;
            })

            .addCase(exportStepsAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getStepByIDAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getStepByIDAction.fulfilled, (state, action) => {
                state.loading = false;
            })

            .addCase(getStepByIDAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreStepAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreStepAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreStepAction.rejected, (state, action) => {
                state.loading = false;
            })

    }
});


export const knowledgeBaseStepsActions = stepsSlice.actions;

export default stepsSlice.reducer;