import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STEPS_DEFAULT_SORT_COLUMN, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
  notesData: [],
  loading: false,
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STEPS_LIST_ROWS_DEF,
    sortFieldProp: STEPS_DEFAULT_SORT_COLUMN
  },
  particularClientObj: {}
};

export const getNotesListAction = createAsyncThunk(
  "clientManagement/getNotesListAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.LIST_CLIENT_NOTES}?client_id=${value.clientID}&search=${value.searchKey}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const addNewNotesAction = createAsyncThunk(
  "clientManagement/addNewNotesAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_CLIENT_NOTES}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const editNotesInfoAction = createAsyncThunk(
  "clientManagement/editNotesInfoAction",

  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.ADD_CLIENT_NOTES}`, value);
      return response;
    } catch (error: any) {

      return error;

    }
  }
);

export const getNotesDetailAction = createAsyncThunk(
  "knowledgebaseModule/getNotesDetailAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.ADD_CLIENT_NOTES}/${value.noteId}`
    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;

    }
  }
);

export const deleteNotesAction = createAsyncThunk(
  "clientManagement/deleteNotesAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.ADD_CLIENT_NOTES}/${value.notes_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const exportNotesAction = createAsyncThunk(
  "clientManagement/exportNotesAction",
  async (value: any) => {
    try {
      const apiUrl = `${EndPoint.EXPORT_LIST_CLIENT_NOTES}?search=${value.searchKey}&notes_id=${value.selectedIds}`
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const notesSlice = createSlice({
  name: 'notes',
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
    },
    clearParticularClientObj(state, actions) {
      state.particularClientObj = {};
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotesListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotesListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getNotesListAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewNotesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewNotesAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addNewNotesAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getNotesDetailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotesDetailAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getNotesDetailAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editNotesInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editNotesInfoAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(editNotesInfoAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteNotesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotesAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteNotesAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(exportNotesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportNotesAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(exportNotesAction.rejected, (state, action) => {
        state.loading = false;
      })
  }
});


export const notesActions = notesSlice.actions;

export default notesSlice.reducer;