import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { POP_UP_CLOSE_1000, POP_UP_CLOSE_2000, SUCCESS_STATUS, STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    designationManagerData: [],
    loading:false,
    particularStageObj:{},
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN
    }
};

// Get Listing
export const getDesignationManagerListAction = createAsyncThunk(
    "designationManagerModule/getDesignationManagerListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.DESIGNATION_MANAGER_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}` : 
        `${EndPoint.DESIGNATION_MANAGER_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}`;
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);


// Add
export const addNewDesignationManagerAction = createAsyncThunk(
    "designationManagerModule/addNewDesignationManagerAction",
    async (value:any) => {

        const addData:any = {
            designation_name : value.designation_name,
            description: value.description
        }
        try {
            const response = await axiosRequest.post(`${EndPoint.DESIGNATION_MANAGER_ADD_API}`, addData);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

// Delete
export const deleteDesignationManagerAction = createAsyncThunk(
    "designationManagerModule/deleteDesignationManagerAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.DESIGNATION_MANAGER_DELETE_API}/${value.userId}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

// Get Edit Data
export const getDesignationManagerAction = createAsyncThunk(
    "designationManagerModule/getDesignationManagerAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.DESIGNATION_MANAGER_GET_API}/${value}`);
          
            return response;
        } catch (error:any) {

            return error;
        }
    }
);

export const editDesignationManagerAction = createAsyncThunk(
    "designationManagerModule/editDesignationManagerAction",
    async (value:any) => {
        const editData = {
            id: value.id,
            designation_name: value.designation_name,
            description: value.description
        }
        try {
            
            const response = await axiosRequest.put(`${EndPoint.DESIGNATION_MANAGER_EDIT_API}`, editData);
            return response;
        } catch (error:any) {     
            return error;
        }
    }
);

//EXPORT DESIGNATIONS
export const exportDesignationAction = createAsyncThunk(
    "teamsModule/exportDesignationAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.DESIGNATION_MANAGER_EXPORT}?sort=${value.sortField}&sort_order=${value.sortOrder}` :
        `${EndPoint.DESIGNATION_MANAGER_EXPORT}?sort=${value.sortField}&search=${value.searchKey}&sort_order=${value.sortOrder}`
        try {

            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {

            return error;

        }
    }
);


const designationManagerSlice:any = createSlice({
    name: 'knowledgebase & stage',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
        },
    },
    extraReducers: (builder) => {
        builder
            // Listing
            .addCase(getDesignationManagerListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDesignationManagerListAction.fulfilled, (state, action) => {
                
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.designationManagerData = data;
            })

            .addCase(getDesignationManagerListAction.rejected, (state, action) => {
               state.loading = false;
            })

            // Add
            .addCase(addNewDesignationManagerAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewDesignationManagerAction.fulfilled, (state, action) => {
                
                state.loading = false;
            })

            .addCase(addNewDesignationManagerAction.rejected, (state, action) => {
               state.loading = false;
            })

            // Delete
            .addCase(deleteDesignationManagerAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteDesignationManagerAction.fulfilled, (state, action) => {
                state.loading = false;
        
            })
            .addCase(deleteDesignationManagerAction.rejected, (state, action) => {
               state.loading = false;
            })

            // Get Edit Data
            .addCase(getDesignationManagerAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDesignationManagerAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : {}
                state.loading = false;
                state.particularStageObj = data;
            })
            .addCase(getDesignationManagerAction.rejected, (state, action) => {
               state.loading = false;
            })

            // Edit or Update
            .addCase(editDesignationManagerAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editDesignationManagerAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editDesignationManagerAction.rejected, (state, action) => {
               state.loading = false;
            })

            .addCase(exportDesignationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(exportDesignationAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(exportDesignationAction.rejected, (state, action) => {
               state.loading = false;
            })

    }
});

export const designationManagerSliceActions = designationManagerSlice.actions;

export default designationManagerSlice.reducer;