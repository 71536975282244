import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EndPoint from "../../api/Endpoint";
import { axiosRequest } from "../common-api/axios";
import moment from "moment";

const initialState = {
  myCaseFilterGlobal: true,    // My Case Assigned to filter
  teamFilterGlobal: "",         // Team to filter
  toDoFilterDay: 0,             // Day To do
  toDoFilterStartDate: moment().format("YYYY-MM-DD"), // start date To do
  caseProgressFilter: "month",  // Case Progress Filter
  quickGlanceCaseTypeFilter: [],  // Quick Glance Filter
  stageCaseProgressCaseTypeFilter: '', // Stage Case Progress Case Type Filter
  stageCaseProgressUserFilter: [], // Stage Case Progress By User Filter
  durationProgressFilter: "year", // Case Progress Duration Filter
  RFECaseStausDesignationFilter: [], // RFE Case Status Designation Filter
  RFECaseStausDurationFilter: 'month', // RFE Case Status Duration Filter


  recentlyVisitedData: [],  // Recently Visited Data
	toDoData: [],            // To Do Data
  caseProgressData: [],   // Case Progress Data
  quickGlanceData: {},    // Quick Glance Data
  stageCaseProgressData: {}, // Stage Case Progress Data
  performanceData: {}, // Performance Data
  RFECaseStatusData: {}, // RFE Case Status Data 
  RFECaseWeeklyReport: {}, // RFE Case Due Weekly Vise Data 
  caseOverdueListing: [], // Case Overdue Data, Overdue billing data


  caseTypeOptions: [],
  usersListOptions: [],
  allDesignationOptions: <any> [],
  loading: false,
};

// Get Dashboard Combine API
export const getDashboardCombineAPI = createAsyncThunk(
  "dashboardModule/getDashboardCombineAPI",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_COMBINED_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Get Dashboard Recently Visited API
export const getRecentlyVisitedAction = createAsyncThunk(
  "dashboardModule/getRecentlyVisitedAction",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_RECENTLY_VISITED_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Get Dashboard TO DO API
export const getToDoAction = createAsyncThunk(
  "dashboardModule/getToDoAction",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_TO_DO_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Get Dashboard CASE PROGRESS API
export const getCaseProgressAction = createAsyncThunk(
  "dashboardModule/getCaseProgressAction",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_CASE_PROGRESS_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Get Dashboard QUICK GLANCE API
export const getQuickGlanceAction = createAsyncThunk(
  "dashboardModule/getQuickGlanceAction",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_QUICK_GLANCE_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Get Dashboard STAGE CASE PROGRESS API
export const getStageCaseProgressAction = createAsyncThunk(
  "dashboardModule/getStageCaseProgressAction",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_STAGE_CASE_PROGRESS_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Get Dashboard PERFORMANCE API
export const getPerformanceAction = createAsyncThunk(
  "dashboardModule/getPerformanceAction",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_PERFORMANCE_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Get RFE CASE STATUS API
export const getRFECaseStausAction = createAsyncThunk(
  "dashboardModule/getRFECaseStausAction",
  async (value: any) => {
    const apiUrl = EndPoint.DASHBOARD_RFE_CASE_STATUS_API;
    try {
      const response = await axiosRequest.post(apiUrl, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// GETTING COMMON TEAMS FOR CLIENT CASES
export const getAllTeamsForClientCasesAction = createAsyncThunk(
  "dashboardModule/getAllTeamsForClientCasesAction",
  async () => {
      const apiUrl = `${EndPoint.GET_COMMON_TEAMS}`
      try {
          const response = await axiosRequest.get(apiUrl);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

// GETTING ALL CASE TYPE
export const getAllCaseTypeListAction = createAsyncThunk(
  "dashboardModule/getAllCaseTypeListAction",
  async () => {
    const apiUrl = EndPoint.DASHBOARD_CASE_TYPE_API;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// GETTING COMMON USER LIST
export const getDashboardUsersList = createAsyncThunk(
  "dashboardModule/getDashboardUsersList",
  async () => {
      try {
          const response = await axiosRequest.get(`${EndPoint.COMMON_USER_LIST}`);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

// GETTING ALL DESIGNATION LIST
export const getDesignationList = createAsyncThunk(
  "dashboardModule/getDesignationList",
  async () => {
    try {
        const response = await axiosRequest.get(`${EndPoint.DESIGNATION_LIST}`);
        return response;
    } catch (error: any) {
        return error;
    }
  }
);


const dashboardSlice: any = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: { 
    changeMyCase(state, actions) {
      state.myCaseFilterGlobal = actions.payload; // global My Cases
    },
    changeTeam(state, actions) {
      state.teamFilterGlobal = actions.payload; // golbal team
    },
    changeDayToDo(state, actions) {
      state.toDoFilterDay = actions.payload; // to do day
    },
    changeStartDateToDo(state, actions) {
      state.toDoFilterStartDate = actions.payload; // to do start date
    },
    changeCaseProgressFilter(state, actions) {
      state.caseProgressFilter = actions.payload // case progres
    },
    changeQuickGlanceFilter(state, actions) {
      state.quickGlanceCaseTypeFilter = actions.payload // Quick Glance 
    },
    changeStageCaseProgressCaseTypeFilter(state, actions) {
      state.stageCaseProgressCaseTypeFilter = actions.payload // Quick Glance
    },
    changeStageCaseProgressUserFilter(state, actions) {
      state.stageCaseProgressUserFilter = actions.payload // Quick Glance
    },
    changePerformanceFilter(state, actions) {
      state.durationProgressFilter = actions.payload // Performance duration
    },
    changeRFECaseStatusDesignationFilter(state, actions) {
      state.RFECaseStausDesignationFilter = actions.payload // Performance duration
    },
    changeRFECaseStatusDurationFilter(state, actions) {
      state.RFECaseStausDurationFilter = actions.payload // RFE Case Status
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
			// COMBINE DATA API
			.addCase(getDashboardCombineAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardCombineAPI.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
				state.recentlyVisitedData = data.recentlyVisited; // Recently Visited data 
				state.toDoData = data.toDoItemsCase; // To Do Data
        state.quickGlanceData = data.caseQuickGlance; // Quick Glance Data
        state.caseProgressData = data.caseProgress; // Case Progress Data
        state.stageCaseProgressData = data.caseStageWise // Stage Case Progress Data
        state.performanceData = data.caseProgressOpenAndFilled // Performance Data
        state.RFECaseStatusData = data.RFECaseStatusCurrentMonth // RFE Case Status Data
        state.RFECaseWeeklyReport = data.RFECaseWeeklyReport // RFE Case Status Data
        state.caseOverdueListing = data.caseOverdueListing // Over Due Billing Data
      })
      .addCase(getDashboardCombineAPI.rejected, (state, action) => {
        state.loading = false;
      })

      // RECENTLY VISITED
      .addCase(getRecentlyVisitedAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecentlyVisitedAction.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
        state.recentlyVisitedData = data.recentlyVisited; // Recently Visited data 
      })
      .addCase(getRecentlyVisitedAction.rejected, (state, action) => {
        state.loading = false;
      })

      // TO DO
      .addCase(getToDoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getToDoAction.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
				state.toDoData = data.toDoItemsCase; // To Do Data

      })
      .addCase(getToDoAction.rejected, (state, action) => {
        state.loading = false;
      })

      // CASE PROGRESS
      .addCase(getCaseProgressAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCaseProgressAction.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
				state.caseProgressData = data.caseProgress; // Case Progress Data
      })
      .addCase(getCaseProgressAction.rejected, (state, action) => {
        state.loading = false;
      })

      // QUICK GLANCE  
      .addCase(getQuickGlanceAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuickGlanceAction.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
				state.quickGlanceData = data.quickGlanceCase // Quick Glance Data
      })
      .addCase(getQuickGlanceAction.rejected, (state, action) => {
        state.loading = false;
      })

      // STAGE CASE PROGRESS
      .addCase(getStageCaseProgressAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStageCaseProgressAction.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
				state.stageCaseProgressData = data.caseStageWiseCount // Stage Case Progress Data        
      })
      .addCase(getStageCaseProgressAction.rejected, (state, action) => {
        state.loading = false;
      })

      // PERFORMANCE
      .addCase(getPerformanceAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPerformanceAction.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
				state.performanceData = data.caseProgressOpenAndFilled // Performance Data
      })
      .addCase(getPerformanceAction.rejected, (state, action) => {
        state.loading = false;
      })

      // RFE CASE STATUS 
      .addCase(getRFECaseStausAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRFECaseStausAction.fulfilled, (state, action) => {
        let data = action.payload ? action.payload : [];
        state.loading = false;
				state.RFECaseStatusData = data.rfeCaseMonth // RFE Case Status Data
      })
      .addCase(getRFECaseStausAction.rejected, (state, action) => {
        state.loading = false;
      })

      // ALL TEAM DATA
      .addCase(getAllTeamsForClientCasesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTeamsForClientCasesAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllTeamsForClientCasesAction.rejected, (state, action) => {
        state.loading = false;
      })

      // ALL CASE TYPE DATA 
      .addCase(getAllCaseTypeListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCaseTypeListAction.fulfilled, (state, action) => {
        let respData = action.payload.data ? action.payload.data : [];
        if (respData.length > 0) {
          const caseTypeListData = respData?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }));
          state.caseTypeOptions = caseTypeListData;
            let firstItem = ''
            if(respData?.length > 0) {
              firstItem = respData[0]?.id
            }
          state.stageCaseProgressCaseTypeFilter = state.stageCaseProgressCaseTypeFilter ? state.stageCaseProgressCaseTypeFilter : firstItem// Stage Case Progress Case Type Filter
        }
        state.loading = false;
      })
      .addCase(getAllCaseTypeListAction.rejected, (state, action) => {
        state.loading = false;
      })

      // ALL USERS DATA 
      .addCase(getDashboardUsersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardUsersList.fulfilled, (state, action) => {
        let respData = action.payload.data ? action.payload.data : [];
        if (respData.length > 0) {
          const userListData = respData?.map((item: any) => ({
            label: `${item.full_name} (${item.email})`,
            value: item.id,
          }));
          state.usersListOptions = userListData;
        }
        state.loading = false;
      })
      .addCase(getDashboardUsersList.rejected, (state, action) => {
        state.loading = false;
      })

      // ALL DESIGNATION DATA 
      .addCase(getDesignationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDesignationList.fulfilled, (state, action) => {
        let respData = action.payload ? action.payload.data : [];
        if (respData.length > 0) {
          const designationAllData = respData?.map((item: any) => ({
            label: item.designation_name,
            value: item.id,
          }));
          state.allDesignationOptions = designationAllData;
          if(state.RFECaseStausDesignationFilter?.length === 0){
            state.RFECaseStausDesignationFilter = designationAllData ? designationAllData?.map((item: any) => item?.value)  : []
          }
        }
        state.loading = false;
      })
      .addCase(getDesignationList.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const dashboardSliceActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
