import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import EndPoint from '../../api/Endpoint';
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
import { POP_UP_CLOSE_1000, POP_UP_CLOSE_2000, SUCCESS_STATUS, STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    knowledgeBaseFormData: [],
    loading:false,
    particularStageObj:{},
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
        startDateProp: null,
        endDateProp: null,
        deletedFormProp: false,
    }
};

export const getKnowledgeBaseFormsListAction = createAsyncThunk(
    "knowledgebaseFormsModule/getKnowledgeBaseFormsListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.KB_FORMS_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&start_date=${value.startDate}&end_date=${value.endDate}&filtereByDeletedForm=${value.deletedForm}` : 
        `${EndPoint.KB_FORMS_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&start_date=${value.startDate}&end_date=${value.endDate}&filtereByDeletedForm=${value.deletedForm}`;
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const addNewFormAction = createAsyncThunk(
    "knowledgebaseFormsModule/addNewFormAction",
    async (value:any) => {
        const addData:any = { 
            form_name : value.form_name.trim(),
            code: value.form_code.trim(),
            description: value.description.trim(),
            form_edition_date: value.editionDate,
            form_last_reviewed_date: value.lastReviewedDate,
            disable_auto_update: value.is_active,
            form_page_url: value.page_url.trim(),
            form_pdf_url: value.form_url.trim()
        }
        try {
            const response = await axiosRequest.post(`${EndPoint.KB_FORMS_ADD_API}`, addData);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

export const addNewFormDataAction = createAsyncThunk(
    "knowledgebaseFormsModule/addNewFormDataAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.post(`${EndPoint.KB_FORMS_ADD_API}`, value);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

export const deleteFormAction = createAsyncThunk(
    "knowledgebaseFormsModule/deleteFormAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.KB_FORMS_DELETE_API}/${value.itemId}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

export const getFormAction = createAsyncThunk(
    "knowledgebaseFormsModule/getFormAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.KB_FORMS_GET_OBJ_API}/${value}`);
          
            return response;
        } catch (error:any) {

            return error;
        }
    }
);

export const editFormAction = createAsyncThunk(
    "knowledgebaseFormsModule/editFormAction",
    async (value:any) => {
        // const editData:any = {
        //     id: value.id,
        //     form_name : value.form_name.trim(),
        //     code: value.form_code.trim(),
        //     description: value.description.trim(),
        //     form_edition_date: value.editionDate,
        //     form_last_reviewed_date: value.lastReviewedDate,
        //     disable_auto_update: value.is_active,
        //     form_page_url: value.page_url.trim(),
        //     form_pdf_url: value.form_url.trim()
        // }
        try {
            
            const response = await axiosRequest.put(`${EndPoint.KB_FORMS_EDIT_API}`, value);
            return response;
        } catch (error:any) {     
            return error;
        }
    }
);

export const getKnowledgeBaseFormsCommonAction = createAsyncThunk(
    "knowledgebaseFormsModule/getKnowledgeBaseFormsCommonAction",
    async () => {
        try {
            const response = await axiosRequest.get(EndPoint.KB_FORMS_GET_ALL_COMMON);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

/**
 *  Getting Case Type By ID
 *
 * @type {*}
 */
export const getAllFormMappingDetails  = createAsyncThunk(
    "knowledgebaseFormsModule/getAllFormMappingDetails",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.GET_ALL_FORM_MAPPING}?form_id=${value.formTypesID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 * Deletion Of Mapped Questionnaire
 *
 * @type {*}
 */
export const deleteCaseTypesFormMapping = createAsyncThunk(
    "knowledgebaseFormsModule/deleteCaseTypesFormMapping",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.delete(`${EndPoint.GET_ALL_KB_FORM_MAPPING_DELETE}/${value.formTypesID}/${value.questionnaireID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Single Form Mapping Details data
 *
 * @type {*}
 */
export const getFormMappingByFormId = createAsyncThunk(
    "knowledgebaseFormsModule/getFormMappingByFormId",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.GET_FORM_MAPPING_BY_ID_PREVIEW}?questionnaire_id=${value.questionnaireID}&form_id=${value.formTypesID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Case Type By ID
 *
 * @type {*}
 */
export const getCaseTypesTableData = createAsyncThunk(
    "knowledgebaseFormsModule/getCaseTypesTableData",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.get(`${EndPoint.GET_ALL_FORM_MAPPING_ALL_KB}?form_id=${value.formID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// RESTORE STEP
export const restoreStepAction = createAsyncThunk(
    "knowledgebaseFormsModule/restoreStepAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.KB_FORMS_RESTORE_API}`, value);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);


const knowledgeBaseFormSlice:any = createSlice({
    name: 'knowledgebase Form',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
            state.listObj.startDateProp = actions.payload.startDate;
            state.listObj.endDateProp = actions.payload.endDate;
            state.listObj.deletedFormProp = actions.payload.deletedForm;
        },
        startLoader(state,actions) {
            state.loading = true;
        },
        stopLoader(state,actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getKnowledgeBaseFormsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseFormsListAction.fulfilled, (state, action) => {
                
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.knowledgeBaseFormData = data;
            })

            .addCase(getKnowledgeBaseFormsListAction.rejected, (state, action) => {
               state.loading = false;
            })

            .addCase(addNewFormAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewFormAction.fulfilled, (state, action) => {

                state.loading = false;
            })

            .addCase(addNewFormAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(addNewFormDataAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewFormDataAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addNewFormDataAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(deleteFormAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteFormAction.fulfilled, (state, action) => {
                state.loading = false;
        
            })
            .addCase(deleteFormAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getFormAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFormAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : {}
                state.loading = false;
                state.particularStageObj = data;
            })
            .addCase(getFormAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(editFormAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editFormAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editFormAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getKnowledgeBaseFormsCommonAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseFormsCommonAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getKnowledgeBaseFormsCommonAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getAllFormMappingDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllFormMappingDetails.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllFormMappingDetails.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(deleteCaseTypesFormMapping.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCaseTypesFormMapping.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteCaseTypesFormMapping.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getFormMappingByFormId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFormMappingByFormId.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getFormMappingByFormId.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getCaseTypesTableData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCaseTypesTableData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getCaseTypesTableData.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(restoreStepAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreStepAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreStepAction.rejected, (state, action) => {
               state.loading = false;
            })
    }
});

export const knowledgeBaseFormActions = knowledgeBaseFormSlice.actions;

export default knowledgeBaseFormSlice.reducer;