import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import EndPoint from "../../api/Endpoint";
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from "../../validations/validationErrors";
import {
  POP_UP_CLOSE_1000,
  POP_UP_CLOSE_2000,
  SUCCESS_STATUS,
  STAGES_LIST_ROWS_DEF,
  LIST_CRR_PAGE,
  DEF_SORT_ORDER,
  STAGES_DEFAULT_SORT_COLUMN,
} from "../../constants/global";
import { axiosRequest } from "../common-api/axios";

const initialState = {
  reminderData: [],
  loading: false,
  listObjData: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STAGES_LIST_ROWS_DEF,
    sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
    statusOptionsProp: "",
    searchStartDateProp: "",
    searchEndDateProp: "",
  },
};

export const getReminderListAction = createAsyncThunk(
  "reminderModule/getReminderListAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.REMINDER_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey ? value.searchKey : ""}&sort=${value.sortField}&sort_order=${value.sortOrder}&status=${value.statusOptions ? value.statusOptions : ""}&searchstartDate=${value.searchstartDate ? value.searchstartDate : ""}&searchendDate=${value.searchendDate ? value.searchendDate : ""}`;
    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Mark as Complete Reminder
export const markAsCompletedReminderAction = createAsyncThunk(
  "reminderModule/markAsCompletedReminderAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.REMINDER_MARK_COMPLETED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Delete Reminder from List
export const deleteReminderActionFromReminder = createAsyncThunk(
  "reminderModule/deleteReminderActionFromReminder",
  async (value: any) => {
      try {
          const response = await axiosRequest.delete(`${EndPoint.REMINDER_DELETE}/${value.reminder_id}`);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

//EDIT REMINDERS 
export const editReminderActionForReminders = createAsyncThunk(
  "reminderModule/editReminderActionForReminders",
  async (value: any) => {
      try {
          const response = await axiosRequest.put(`${EndPoint.REMINDER_EDIT}`, value);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);


const reminderSlice: any = createSlice({
  name: "reminder",
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObjData.searchKeyProp = actions.payload.searchKey;
      state.listObjData.sortOrderProp = actions.payload.sortOrder;
      state.listObjData.currentPageProp = actions.payload.currentPage;
      state.listObjData.rowsProp = actions.payload.rows;
      state.listObjData.sortFieldProp = actions.payload.sortField;
      state.listObjData.statusOptionsProp = actions.payload.statusOptions;
      state.listObjData.searchStartDateProp = actions.payload.searchStartDate;
      state.listObjData.searchEndDateProp = actions.payload.searchEndDate;
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReminderListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReminderListAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data : [];
        state.loading = false;
        state.reminderData = data;
      })
      .addCase(getReminderListAction.rejected, (state, action) => {
        state.loading = false;
      })
			.addCase(markAsCompletedReminderAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(markAsCompletedReminderAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(markAsCompletedReminderAction.rejected, (state, action) => {
        state.loading = false;
      })
			.addCase(deleteReminderActionFromReminder.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReminderActionFromReminder.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteReminderActionFromReminder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editReminderActionForReminders.pending, (state) => {
        state.loading = true;
      })
      .addCase(editReminderActionForReminders.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editReminderActionForReminders.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const reminderActions = reminderSlice.actions;

export default reminderSlice.reducer;
