import React from 'react';
import { Formik, Form, FormikProps } from 'formik';

interface CommonFormikComponentProps {
  validationSchema: any;
  initialValues: Record<string, any>;
  onSubmit: (values: Record<string, any>, actions: any) => void;
  children: (formikProps: FormikProps<Record<string, any>>, isSubmitting: boolean, isValid: boolean) => React.ReactNode;
  innerRef?: any;
}

const CommonFormikComponent: React.FC<CommonFormikComponentProps> = ({
  validationSchema,
  initialValues,
  onSubmit,
  children,
  innerRef
}) => {
  return (
    <Formik
      innerRef={innerRef}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form noValidate onSubmit={formikProps.handleSubmit} className="theme-common-form">
          {children(formikProps, formikProps.isSubmitting, formikProps.isValid)}
        </Form>
      )}
    </Formik>
  );
};

export default CommonFormikComponent;