import { configureStore } from '@reduxjs/toolkit';
import RootReducer from './reducer/root/root-reducer'; 
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import appConfig from "../helpers/config";


//Redux Storage Encrption Method
const encryptor = encryptTransform({
  secretKey: appConfig.reduxSecretKey,
  onError: function (error) {
    // Handle the error.
  },
});

//Redux Persist Method
const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
};


const persistedReducer = persistReducer(persistConfig, RootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    })
    // .concat(logger);
  },
  devTools: false,
});

export const persistor = persistStore(store);
  