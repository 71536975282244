import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EndPoint from "../../api/Endpoint";
import {
  STAGES_LIST_ROWS_DEF,
  LIST_CRR_PAGE,
  DEF_SORT_ORDER,
  STAGES_DEFAULT_SORT_COLUMN,
} from "../../constants/global";
import { axiosRequest } from "../common-api/axios";

const initialState = {
  knowledgeBaseDocumentsData: [],
  loading: false,
  particularDocumentsObj: {},
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STAGES_LIST_ROWS_DEF,
    sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
    deletedDocumentProp: false
  },
};

/**
 * Getting Documetns List
 *
 * @type {*}
 */

export const getKnowledgeBaseDocumentsListAction = createAsyncThunk(
  "knowledgebasedocumentsModule/getKnowledgeBaseDocumentsListAction",
  async (value: any) => {
    const apiUrl =
      value.searchKey === ""
        ? `${EndPoint.KB_DOCUMENTS_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedDocument=${value.deletedDocument}`
        : `${EndPoint.KB_DOCUMENTS_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedDocument=${value.deletedDocument}`;
    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Getting Documetn Types List
 *
 * @type {*}
 */
export const getKnowledgeBaseDocumetnsTypesListAction = createAsyncThunk(
  "knowledgebasedocumentsModule/getKnowledgeBaseDocumetnsTypesListAction",
  async () => {
    const apiUrl = EndPoint.KB_DOC_TYPE_LIST_API;
    try {
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Adding Document
 *
 * @type {*}
 */
export const addNewDocumentAction = createAsyncThunk(
  "knowledgebasedocumentsModule/addNewDocumentAction",
  async (value: any) => {
    // const addData: any = {
    //   document_name: value.document_name.trim(),
    //   document_type_id:
    //     value.document_type_id === "" ? null : value.document_type_id,
    //   upload_to_folder: value.upload_to_folder,
    //   uploaded_document_url: value.sample_document_url,
    //   description: value.description.trim(),
    // };
    try {
      const response = await axiosRequest.post(
        `${EndPoint.KB_DOCUMENTS_ADD_API}`,
        value
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 *  Edit Document
 *
 * @type {*}
 */
export const deleteDocumentAction = createAsyncThunk(
  "knowledgebasedocumentsModule/deleteDocumentAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(
        `${EndPoint.KB_DOCUMENTS_DELETE_API}/${value.userId}`
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 *  Get Edit Document Data
 *
 * @type {*}
 */
export const getKnowledgeBaseDocumetnsAction = createAsyncThunk(
  "knowledgebasedocumentsModule/getKnowledgeBaseDocumetnsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(
        `${EndPoint.KB_DOCUMENTS_GET_API}/${value}`
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 *  Edit Document
 *
 * @type {*}
 */

export const editKnowledgeBaseDocumetnsAction = createAsyncThunk(
  "knowledgebasedocumentsModule/editKnowledgeBaseDocumetnsAction",
  async (value: any) => {
    // const editData = {
    //   id: value.id,
    //   document_name: value.document_name.trim(),
    //   document_type_id:
    //     value.document_type_id === "" ? null : value.document_type_id,
    //   upload_to_folder: value.upload_to_folder,
    //   uploaded_document_url: value.sample_document_url,
    //   description: value.description.trim(),
    // };
    try {
      const response = await axiosRequest.put(
        `${EndPoint.KB_DOCUMENTS_EDIT_API}`,
        value
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// RESTORE Document 
export const restoreDocumentAction = createAsyncThunk(
  "knowledgebasedocumentsModule/restoreDocumentAction",
  async (value: any) => {
      try {
          const response = await axiosRequest.put(`${EndPoint.KB_DOCUMENTS_RESTORE_API}`, value);
          return response;
      } catch (error: any) {

          return error;

      }
  }
);

const knowledgeBaseDocumentsSlice: any = createSlice({
  name: "knowledgebase & documents",
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
      state.listObj.deletedDocumentProp = actions.payload.deletedDocument;
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKnowledgeBaseDocumentsListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getKnowledgeBaseDocumentsListAction.fulfilled,
        (state, action) => {
          let data = action.payload.data ? action.payload.data : [];
          state.loading = false;
          state.knowledgeBaseDocumentsData = data;
        }
      )
      .addCase(
        getKnowledgeBaseDocumentsListAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      .addCase(getKnowledgeBaseDocumetnsTypesListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getKnowledgeBaseDocumetnsTypesListAction.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        getKnowledgeBaseDocumetnsTypesListAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(addNewDocumentAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewDocumentAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteDocumentAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDocumentAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Get Edit Data
      .addCase(getKnowledgeBaseDocumetnsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKnowledgeBaseDocumetnsAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data : {};
        state.loading = false;
        state.particularDocumentsObj = data;
      })
      .addCase(getKnowledgeBaseDocumetnsAction.rejected, (state, action) => {
        state.loading = false;
      })

      // Edit or Update
      .addCase(editKnowledgeBaseDocumetnsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editKnowledgeBaseDocumetnsAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editKnowledgeBaseDocumetnsAction.rejected, (state, action) => {
        state.loading = false;
      })
      // Restore
      .addCase(restoreDocumentAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreDocumentAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const knowledgeBaseDocumentsActions =
  knowledgeBaseDocumentsSlice.actions;

export default knowledgeBaseDocumentsSlice.reducer;
