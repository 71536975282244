import { createSlice } from '@reduxjs/toolkit';
let isAuthenticated=localStorage.getItem('isLoggedIn')

const userInfo = {};
const initialAuthState = {
  isAuthenticated: false,
  userInfo:userInfo,
};

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login(state,action) {
      state.isAuthenticated = true;
      state.userInfo = action.payload.data;
    },
    logout(state) {
      state.isAuthenticated = false;
     
    }
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;