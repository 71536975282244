import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, TEAMS_DEFAULT_SORT_COLUMN, TEAMS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    notificationData: [],
    notificationLatestData: [],
    loading: false,
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: TEAMS_LIST_ROWS_DEF,
        sortFieldProp: TEAMS_DEFAULT_SORT_COLUMN
    }

};

//NOTIFICATION LIST
export const notificationListAction = createAsyncThunk(
    "notificationModule/notificationListAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.GET_NOTIFICATION_LIST}?page=${value.currentPage}&size=${value.rows}&sort_order=${value.sortOrder}` : `${EndPoint.GET_NOTIFICATION_LIST}?search=${value.searchKey}&page=${value.currentPage}&size=${value.rows}&sort_order=${value.sortOrder}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//LATEST NOTIFICATION LIST
export const notificationLatestAction = createAsyncThunk(
    "notificationModule/notificationLatestAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.GET_LATEST_NOTIFICATION_LIST}?size=${value.rows}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//LATEST NOTIFICATION UNREAD LIST
export const notificationUnreadAction = createAsyncThunk(
    "caseManagement/notificationUnreadAction",
    async () => {
        try {
            const response = await axiosRequest.put(`${EndPoint.GET_NOTIFICATION_MARK_READ_LIST}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//DELETE NOTIFICATION
export const deleteNotificationAction = createAsyncThunk(
    "notificationModule/deleteNotificationAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.NOTIFICATION_DELETE}/${value.notification_id}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.searchKeyProp = actions.payload.searchKey;
            state.listObj.sortOrderProp = actions.payload.sortOrder;
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
            state.listObj.sortFieldProp = actions.payload.sortField;
        },
        startLoader(state, actions){
            state.loading = true;
        },
        stopLoader(state, actions){
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(notificationListAction.pending, (state) => {
                
            })
            .addCase(notificationListAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : [];
                state.notificationData = data
                state.loading = false;
            })
            .addCase(notificationListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteNotificationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteNotificationAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteNotificationAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(notificationLatestAction.pending, (state) => {
                
            })
            .addCase(notificationLatestAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : [];
                state.notificationLatestData = data
                state.loading = false;
            })
            .addCase(notificationLatestAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(notificationUnreadAction.pending, (state) => {
                
            })
            .addCase(notificationUnreadAction.fulfilled, (state, action) => {
                
            })
            .addCase(notificationUnreadAction.rejected, (state, action) => {
               
            })
    }
});


export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;