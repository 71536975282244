import { useNavigate } from 'react-router-dom';
import { SearchSchema } from '../../../../validations/search';
import CommonFormikComponent from '../../CommonFormikComponent';
import ThemeInputSearch from '../../InputSearch';
import { baseRoutes } from '../../../../routes/base-routes';
import { getAllGlobalSearchData, globalSearchActions } from '../../../../redux/globalSearch/globalSearchSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoIosClose, IoIosSearch } from 'react-icons/io';

const HeaderSearch = () => {
    const formikRef = useRef<any>()
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const navigate = useNavigate();
    const isLoading = useSelector((state: any) => state.globalSearchSlice.loading);
    const mobileSearch = useSelector((state: any) => state.globalSearchSlice.mobileSearch);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);

    // Initial Values
    const initialValues: Record<string, any> = {
        search: ''
    };

    useEffect(() => {
        setToggleSearch(false)
    }, [mobileSearch])

    // Handle Submit
    const handleSubmit = (values: Record<string, any>, actions: any) => {
        actions.resetForm();
        if(values.search === "") {
            toast.error("Please enter keyword to search.")
            return
        }
        if(values.search?.length <= 2) {
            toast.error("Please enter atleast three characters to search.")
            return
        }
        actions.setSubmitting(false);

        // globalSearchSlice
        const payload = {
            "case_status":"",
            "search": values?.search?.trim(),
            "page": 1
        }
        dispatch(globalSearchActions.startLoader(true));
        dispatch(getAllGlobalSearchData(payload)).then(data => {
            navigate(`${baseRoutes.search.path}`, {
                state: { reduxStateStage: true }
        });
        dispatch(globalSearchActions.stopLoader(true));
        dispatch(globalSearchActions.searchKeyFun(values?.search?.trim()));

        }).catch((err: any) => {
            console.log(err);
        })
        .finally(() => dispatch(globalSearchActions.stopLoader(true)));

    };

    const clearSearchHandler = () => {
        formikRef?.current?.setFieldValue("search", "");
    }

    const toggleSearchHandler = () => {
        setToggleSearch(!toggleSearch);
    }

    return (
        <div className={`flex-fill ms-auto max-w-350 text-end custom-mobile-header ${toggleSearch ? 'toggle-search-active' : ''}`}>
            <Loader classCustom={'theme-loader-z-index'} isLoading={isLoading} />
            <CommonFormikComponent
                validationSchema={SearchSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                innerRef={formikRef}
            >
                {(formikProps) => (
                    <ThemeInputSearch
                        inputWrapClass="p-inputgroup"
                        inputClassName="bg-body-secondary border-light-subtle"
                        buttonClassName="border-light-subtle"
                        controlId="search"
                        placeholder='Search'
                        handleBlur={formikProps.handleBlur}
                        handleChange={formikProps.handleChange}
                        value={formikProps.values.search}
                        touched={formikProps.touched.search}
                        errorsField={formikProps.errors.search}
                        disabled={window?.location?.pathname?.includes('search')}
                        clearSearchHandler={clearSearchHandler}
                        disableSearchButton={window?.location?.pathname?.includes('search')}
                    />
                )}
            </CommonFormikComponent>

            <Button
                variant="link"
                className="border-0 flex-shrink-0 h-39 lh-sm p-0 position-relative text-body w-39 z-3 d-md-none"
                aria-label="Search Toggle"
                onClick={toggleSearchHandler}
                disabled={window?.location?.pathname?.includes('search')}
            >
                {toggleSearch ? <IoIosClose size={36} /> : <IoIosSearch size={24} />}
                <span className="visually-hidden">Search</span>
            </Button>
        </div>
    )
}

export default HeaderSearch