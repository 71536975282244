import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import EndPoint from '../../api/Endpoint';
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
import { POP_UP_CLOSE_1000, POP_UP_CLOSE_2000, SUCCESS_STATUS, STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

let initialState = {
    loading: false,
    calendarView: "dayGridMonth",  // dayGridMonth, timeGridWeek, timeGridDay
    filterObj: {
        outlook: false,
        stepsDates: false,
        dueDates: false,
        reminders: false,
        planner: false,
        events: false,

        Interview: false,
        Appointment: false,
        Court_Date: false,
        Meeting: false,
        Others: false,
    },
};

export const calendarListDataAction = createAsyncThunk(
    "calendarModule/calendarListDataAction",
    async (value:any) => {
        const apiUrl = EndPoint.CALENDAR_API
        try {
            const response = await axiosRequest.post(apiUrl, value);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);



const calendarSlice = createSlice({
    name: 'calendar',
    initialState: initialState,
    reducers: {
        changeFilterDataObj(state, actions) {
            state.filterObj.outlook = actions.payload.outlook;
            state.filterObj.stepsDates = actions.payload.stepsDates;
            state.filterObj.dueDates = actions.payload.dueDates;
            state.filterObj.reminders = actions.payload.reminders;
            state.filterObj.planner = actions.payload.planner;
            state.filterObj.events = actions.payload.events;
            state.filterObj.Interview = actions.payload.Interview;
            state.filterObj.Appointment = actions.payload.Appointment;
            state.filterObj.Court_Date = actions.payload.Court_Date;
            state.filterObj.Meeting = actions.payload.Meeting;
            state.filterObj.Others = actions.payload.Others;      
        },
        changeCalendarView(state, actions){
            state.calendarView = actions.payload;
        },
        startLoader(state,actions) {
            state.loading = true;
        },
        stopLoader(state,actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(calendarListDataAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(calendarListDataAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(calendarListDataAction.rejected, (state, action) => {
               state.loading = false;
            })

            
    }
});

export const calendarActions = calendarSlice.actions;

export default calendarSlice.reducer;