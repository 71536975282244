import { Navigate, RouteObject, useLocation, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import { Login } from "../pages/auth/login/Login";
import { useState } from "react";
/**
 *
 * @returns Routes Elements
 */

export default function AppRoutes() {
  const { accounts } = useMsal();

  const location = useLocation();
  let locationVar = location?.pathname ? location?.pathname : "/";
  let newStr = locationVar.replaceAll('/','');
  let locationIsApp = newStr ? newStr == "app" : false;
  let hashCode = location?.hash ? location?.hash : "";
  let hashCodeCondition = hashCode ? hashCode.includes('#code=') : false;
  let accountsLength = accounts ? accounts.length : 0;
  const authDetails = window.localStorage.getItem("user-logged-in-after-verify-email");
  const authDetailCondition = authDetails ? authDetails == "true" : false;
  let newStrLength = newStr ? newStr.length : 0;
  const loginRoute: RouteObject[] = [
    {
      path: "login",
      element: <Login />,
    },
    {path:"*", element:<Navigate to="/login" />}
  ];
  const loginRoutes=useRoutes([...loginRoute]);
  const mainRoutes: RouteObject[] = protectedRoutes;
  const element = useRoutes([...mainRoutes]);
  const elementPublic = useRoutes([...publicRoutes]);
  let [loggedInStatus, setLoggedInStatus] = useState(false);
  return (
    <>
      {
        (() => {
          if(hashCodeCondition && !loggedInStatus){
            setLoggedInStatus(true);
          }
          if(accountsLength > 0 && loggedInStatus){
            setLoggedInStatus(false);
          }
          
          console.log("###### accountsLength ######", accountsLength, "authDetailCondition",authDetailCondition, "###### hashCodeCondition #####", hashCodeCondition, "######  locationIsApp ####", locationIsApp,"##### loggedInStatus #####", loggedInStatus);

          if (accountsLength < 1 && (newStrLength > 3 || !loggedInStatus) && !authDetailCondition) {   
            return loginRoutes;
          } else{
            return(
              <>
                <AuthenticatedTemplate>{element}</AuthenticatedTemplate>
                <UnauthenticatedTemplate>{elementPublic}</UnauthenticatedTemplate>
              </>
            )
          } 
      })()
    }
    
    
    </>
  );
}