import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { axiosRequest } from '../common-api/axios';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STEPS_LIST_ROWS_DEF, STEPS_DEFAULT_SORT_COLUMN } from '../../constants/global';

const initialState = {
  caseData: [],
  loading: false,
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STEPS_LIST_ROWS_DEF,
    sortFieldProp: STEPS_DEFAULT_SORT_COLUMN,
    searchAlienNumber: '',
    searchCurrentlyinUS: "",
    searchNationalities: [],
    searchLanguage: [],
    searchstartDate: '',
    searchendDate: '',
    searchCaseType: '',
    searchCaseStatus: '',
    searchDesignations: []
  },
};


/**
 * Getting New Generated Report List
 *
 * @type {*}
 */
export const getReportListAction = createAsyncThunk(
  "reportsModule/getReportListAction",
  async (value: any) => {
    const payload = {
      "page": String(value.currentPage),
      "size": String(value.rows),
      "sort": value.sortField,
      "sort_order": value.sortOrder,
      "search": value.searchKey ? value.searchKey : '',
      "searchCaseSelfAssignee": value.searchCaseAssginee ?? '',
      "company_id": value.searchCompany ? value.searchCompany : [],
      "searchAlienNumber": value.searchAlienNumber ? value.searchAlienNumber : '',
      "searchCaseType": value.searchCaseType ? value.searchCaseType : [],
      "searchCaseStatus": value.searchCaseStatus ? value.searchCaseStatus : '',
      "searchCurrentlyinUS": value.searchCurrentlyinUS ?? '',
      "searchNationalities": value.searchNationalities?.length > 0 ? value.searchNationalities : [],
      "searchLanguage": value.searchLanguage?.length > 0 ? value.searchLanguage : [],
      "searchstartDate": value.searchstartDate ? value.searchstartDate : '',
      "searchendDate": value.searchendDate ? value.searchendDate : '',
      "searchByDesignatedUsers": value.searchDesignations ? value.searchDesignations : []
    }
    const apiUrl = EndPoint.REPORT_LIST_API
    try {
      const response = await axiosRequest.post(apiUrl, payload);
      return response;
    } catch (error: any) {
      return error;

    }
  }
);

/**
 * Adding or saving New Reports 
 *
 * @type {*}
 */
export const saveNewReports = createAsyncThunk(
  "reportsModule/addNewReports",
  async (value: any) => {
      try {
          const response = await axiosRequest.post(`${EndPoint.REPORT_SAVE
          }`, value);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

/**
 * Update  Reports 
 *
 * @type {*}
 */
export const updateSavedReport = createAsyncThunk(
  "reportsModule/updateSavedReport",
  async (value: any) => {
      try {
          const response = await axiosRequest.put(`${EndPoint.REPORT_UPDATE
          }`, value);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

/**
 * Getting All Desgination List
 *
 * @type {*}
 */
export const getDesignationiListAction = createAsyncThunk(
  "reportsModule/getDesignationiListAction",
  async () => {
    try {
      const response = await axiosRequest.get(EndPoint.ALL_DESIGNATION_LIST);
      return response;
    } catch (error: any) {
      return error;

    }
  }
);

//EXPORT Report
export const exportReportsAction = createAsyncThunk(
  "reportsModule/exportReportsAction",
  async (value: any) => {
    const payload = {
      sort: value.sortField,
      sort_order: value.sortOrder,
      search: value.searchKey ? value.searchKey : "",
      searchCaseSelfAssignee: value.searchCaseAssginee ?? "",
      company_id: value.searchCompany ? value.searchCompany : [],
      searchAlienNumber: value.searchAlienNumber ? value.searchAlienNumber : "",
      searchCaseType: value.searchCaseType ? value.searchCaseType : [],
      searchCaseStatus: value.searchCaseStatus ? value.searchCaseStatus : "",
      searchCurrentlyinUS: value.searchCurrentlyinUS ?? "",
      searchNationalities:
        value.searchNationalities?.length > 0 ? value.searchNationalities : [],
      searchLanguage:
        value.searchLanguage?.length > 0 ? value.searchLanguage : [],
      searchstartDate: value.searchstartDate ? value.searchstartDate : "",
      searchendDate: value.searchendDate ? value.searchendDate : "",
      searchByDesignatedUsers: value.searchDesignations
        ? value.searchDesignations
        : [],
      columns: value.columns ? value.columns : [],
    };
    const apiUrl = EndPoint.REPORTS_EXPORT
    try {
      const response = await axiosRequest.post(apiUrl, {filter: payload});
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//GET ALL ACTIVE COMPANY ITEMS
export const getAllActiveCompany = createAsyncThunk(
  "reportsModule/getAllActiveCompany",
  async () => {
    try {
      const response = await axiosRequest.get(EndPoint.GET_ALL_ACTIVE_COMPANY_LIST_API);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const reportSlice = createSlice({
  name: 'Reports',
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
      state.listObj.searchAlienNumber = actions.payload.searchAlienNumber;
      state.listObj.searchCurrentlyinUS = actions.payload.searchCurrentlyinUS;
      state.listObj.searchNationalities = actions.payload.searchNationalities;
      state.listObj.searchLanguage = actions.payload.searchLanguage;
      state.listObj.searchstartDate = actions.payload.searchstartDate;
      state.listObj.searchendDate = actions.payload.searchendDate;
      state.listObj.searchCaseType = actions.payload.searchCaseType;
      state.listObj.searchCaseStatus = actions.payload.searchCaseStatus;
      state.listObj.searchDesignations = actions.payload.searchDesignations;

    },

    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportListAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(saveNewReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveNewReports.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveNewReports.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDesignationiListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDesignationiListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getDesignationiListAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(exportReportsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportReportsAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(exportReportsAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSavedReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSavedReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSavedReport.rejected, (state, action) => {
        state.loading = false;
      })
      // All Company 
      .addCase(getAllActiveCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActiveCompany.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getAllActiveCompany.rejected, (state, action) => {
        state.loading = false;
      })
      
  }
});

export const reportSliceActions = reportSlice.actions;

export default reportSlice.reducer;