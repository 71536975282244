import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    settings: [],
    loading: false,
};

// GETTING Settings LIST
export const getSettingsListAction = createAsyncThunk(
    "settingsModule/getSettingsListAction",
    async () => {
        const apiUrl = `${EndPoint.GET_SETTING_LIST}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// Edit Settings LIST
export const editSettingsListAction = createAsyncThunk(
    "settingsModule/editSettingsListAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.EDIT_SETTING_LIST}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const settingsSlice = createSlice({
    name: 'Settings',
    initialState: initialState,
    reducers: {
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSettingsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSettingsListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getSettingsListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(editSettingsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editSettingsListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editSettingsListAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;