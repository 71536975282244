import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import EndPoint from '../../api/Endpoint';
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, POP_UP_CLOSE_1000, POP_UP_CLOSE_2000, STEPS_DEFAULT_SORT_COLUMN, STEPS_LIST_ROWS_DEF, SUCCESS_STATUS, SUCCESS_STATUS_ERROR } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';
const initialState = {
    userMgtData: [],
    usersRolesList: [],
    designationList: [],
    userDetailsById: {},
    commonUsersList: [],
    loading: false,
    listObj: {
        deletedUserProp: false,
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STEPS_LIST_ROWS_DEF,
        sortFieldProp: STEPS_DEFAULT_SORT_COLUMN,
    }
};


export const getUserMgtListAction = createAsyncThunk(
    "userManagementModule/getUserMgtListAction",
    async (value: any, thunkAPI) => {
        const apiUrl = value.searchKey === "" ?
            `${EndPoint.LIST_USER_MANAGEMENT_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByRole=${value.roleList}&filterByDesignation=${value.designationList}&filtereByDeletedUser=${value.deletedUser}` :
            `${EndPoint.LIST_USER_MANAGEMENT_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByRole=${value.roleList}&filterByDesignation=${value.designationList}&filtereByDeletedUser=${value.deletedUser}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;

        }
    }
);

export const getUserRoles = createAsyncThunk(
    "userManagementModule/getUserRoles",
    async (value: any, thunkAPI) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.ADD_USER_ROLES}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const addUsers = createAsyncThunk(
    "userManagementModule/addUsers",
    async (value: any, thunkAPI) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.post(`${EndPoint.ADD_USER_MANAGEMENT}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


export const updateUsersDetails = createAsyncThunk(
    "userManagementModule/updateUsersDetails",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.put(`${EndPoint.UPDATE_USER_DETAILS}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const updateUsersStatusAction = createAsyncThunk(
    "userManagementModule/updateUsersStatus",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.put(`${EndPoint.STATUS_USER}`, value);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);

export const updateUsersRestoreAction = createAsyncThunk(
    "userManagementModule/updateUsersRestoreAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.RESTORE_USER}`, value);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);

export const deleteUserAction = createAsyncThunk(
    "userManagementModule/deleteUserAction",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.delete(`${EndPoint.USER_DELETE}/${value.roleId}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getUserById = createAsyncThunk(
    "userManagementModule/getUserById",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.get(`${EndPoint.USER_DETAILS_BY_ID}/${value.userID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getDesignationList = createAsyncThunk(
    "userManagementModule/getDesignationList",
    async (value: any, thunkAPI) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.DESIGNATION_LIST}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getCommonUsersList = createAsyncThunk(
    "userManagementModule/getCommonUsersList",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.COMMON_USER_LIST}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


const userMgtSlice = createSlice({
    name: 'User Management',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.deletedUserProp = actions.payload.deletedUser;
            state.listObj.searchKeyProp = actions.payload.searchKey;
            state.listObj.sortOrderProp = actions.payload.sortOrder;
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
            state.listObj.sortFieldProp = actions.payload.sortField;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserMgtListAction.pending, (state) => {
                state.loading = true;

            })
            .addCase(getUserMgtListAction.fulfilled, (state, action) => {
                const userData = action.payload.data ? action.payload.data : [];
                state.loading = false;
                state.userMgtData = userData;

            })
            .addCase(getUserMgtListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getUserRoles.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserRoles.fulfilled, (state, action) => {
                const userRoles = action.payload.data ? action.payload.data : [];
                state.loading = false;
                state.usersRolesList = userRoles;

            })
            .addCase(getUserRoles.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(addUsers.fulfilled, (state, action) => {
                const userMessage = action.payload.message ? action.payload.message : [];
                state.loading = false;

            })
            .addCase(addUsers.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateUsersStatusAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUsersStatusAction.fulfilled, (state, action) => {
                const userMessage = action.payload.message ? action.payload.message : [];
                state.loading = false;

            })
            .addCase(updateUsersStatusAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteUserAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteUserAction.fulfilled, (state, action) => {
                state.loading = false;

            })
            .addCase(deleteUserAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getUserById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                const userData = action.payload.data ? action.payload.data : {};
                state.userDetailsById = userData
                state.loading = false;
            })
            .addCase(getUserById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateUsersDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUsersDetails.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateUsersDetails.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getDesignationList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDesignationList.fulfilled, (state, action) => {
                const designation = action.payload.data ? action.payload.data : [];
                state.loading = false;
                state.designationList = designation;

            })
            .addCase(getDesignationList.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCommonUsersList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCommonUsersList.fulfilled, (state, action) => {
                const userData = action.payload.data ? action.payload.data : [];
                state.loading = false;
                state.commonUsersList = userData;
            })
            .addCase(getCommonUsersList.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateUsersRestoreAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUsersRestoreAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateUsersRestoreAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const authActions = userMgtSlice.actions;

export default userMgtSlice.reducer;