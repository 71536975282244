import { Suspense, createContext, useContext, useEffect } from "react";
import "./assets/css/style.scss";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Loader from "./components/common/Loader";
import AppRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "./helpers/auth-config";
import { getUser } from "./service/graph-api/graph-sdk/common-graph-file";

type AppContext = {
  authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
}

const appContext = createContext<AppContext>({
  authProvider: undefined
});

export function useAppContext(): AppContext {
  return useContext(appContext);
}

function App() {
  const auth = useProvideAppContext();
  return (
    <>
      <appContext.Provider value={auth}>
        <Suspense fallback={<Loader isLoading={true} />}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
        <ToastContainer role="alert" className="text-capitalize" />
      </appContext.Provider>
    </>
  );
}

function useProvideAppContext() {
  const msal = useMsal();
  // <AuthProviderSnippet>
  // Used by the Graph SDK to authenticate API calls
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.accounts[0],
      scopes: loginRequest.scopes,
      interactionType: InteractionType.Popup
    }
  );
  // </AuthProviderSnippet>

  const checkUser = async () => {
      try {
        // Check if user is already signed in
        const account = msal.accounts;
        console.log("##### account #####", account)  

        if (account) {
          // Get the user from Microsoft Graph
          const user = await getUser(authProvider);
          console.log("######### user ##############", user);
        }
      } catch (err: any) {
        console.log("##### Catch Error #####", err)
      }
    }
  // <UseEffectSnippet>
  useEffect(() => {
    // checkUser();
  });
  // </UseEffectSnippet>

  return {
    authProvider
  };
}
export default App;
