import { Card, Image, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginBanner from "../../../assets/images/login-banner.jpg";
import Logo from "../../../assets/images/logo.svg";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../helpers/auth-config";
import { useEffect } from "react";

export const Login = () => {
  const { instance } = useMsal();
  let accountId: string = "";
  useEffect(()=>{
    localStorage.removeItem("user-logged-in-after-verify-email");
  },[]);
  const handleLogin = (loginType: string) => {
    if (loginType === "redirect") {
      instance
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((e) => {});
    }
  };
  function handleResponse(response: any) {
    if (response !== null) {
      accountId = response.account.homeAccountId;
      // Display signed-in user content, call API, etc.
    } else {
      // In case multiple accounts exist, you can select
      const currentAccounts = instance.getAllAccounts();

      if (currentAccounts.length === 0) {
        // no accounts signed-in, attempt to sign a user in
        instance.loginRedirect(loginRequest);
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
      } else if (currentAccounts.length === 1) {
        accountId = currentAccounts[0].homeAccountId;
      }
    }
  }
  return (
    <>
      <Card className="border-0 overflow-auto min-vh-100 w-100 text-center z-1 py-4 px-3 p-md-5">
        <div className="theme-auth-header pt-2 pb-4 w-75 mx-auto">
          <Link to="/" className="d-inline-block">
            <Image src={Logo} alt="Logo" width={263} height={60} fluid />
          </Link>
        </div>
        <Row className="justify-content-center g-0 mt-md-3">
          <Col xs={12}>
            <Card className="px-4 pb-4 px-md-5 pb-md-5 text-start rounded-3 border-0 mx-auto max-w-450">
              <h1 className="py-3 py-md-4 my-1 text-center fs-4 fw-semibold text-primary">
                Login
              </h1>
              <Button
                onClick={() => handleLogin("redirect")}
                className="w-100 btn-lg mt-2 fw-normal"
                type="submit"
              >
                Microsoft Login
              </Button>
            </Card>
          </Col>
        </Row>
        <Card.ImgOverlay className="p-0 pe-none user-select-none z-n1">
          <Card.Img
            src={LoginBanner}
            alt="Login Banner"
            className="w-100 h-100 object-fit-cover"
          />
        </Card.ImgOverlay>
      </Card>
    </>
  );
};
