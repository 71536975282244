/**
 * ALL available SVG icons which can be use in application
 */
export const SvgIcons = {
    caseManagementIcon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path d="M18.85,3H8.35A1.755,1.755,0,0,0,6.6,4.75v10.5A1.755,1.755,0,0,0,8.35,17h10.5a1.755,1.755,0,0,0,1.75-1.75V4.75A1.755,1.755,0,0,0,18.85,3Zm-.875,7.875H9.225V9.125h8.75Zm-3.5,3.5H9.225v-1.75h5.25Zm3.5-7H9.225V5.625h8.75Z" transform="translate(-6.6 -3)" fill="currentColor"/></svg>,

    manageTeamIcon: <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12.6" viewBox="0 0 18 12.6"><path d="M15.05,11.3A2.25,2.25,0,1,0,12.8,9.05,2.243,2.243,0,0,0,15.05,11.3ZM8.3,10.4A2.7,2.7,0,1,0,5.6,7.7,2.689,2.689,0,0,0,8.3,10.4Zm6.75,2.7c-1.647,0-4.95.828-4.95,2.475V17.6H20V15.575C20,13.928,16.7,13.1,15.05,13.1ZM8.3,12.2C6.2,12.2,2,13.253,2,15.35V17.6H8.3V15.575a3.547,3.547,0,0,1,2.133-3.123A11.055,11.055,0,0,0,8.3,12.2Z" transform="translate(-2 -5)" fill="currentColor"/></svg>,

    emailAtIcon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path d="M15.66,6.462A6.6,6.6,0,0,1,15.4,8.027,4.333,4.333,0,0,1,14.732,9.4a3.424,3.424,0,0,1-1.138.977,3.477,3.477,0,0,1-1.662.372,2.609,2.609,0,0,1-1.2-.267,1.967,1.967,0,0,1-.812-.755,2.669,2.669,0,0,1-2.178.992,2.431,2.431,0,0,1-1.086-.241,2.192,2.192,0,0,1-.815-.687A2.88,2.88,0,0,1,5.365,8.72a4.209,4.209,0,0,1-.062-1.4A6.022,6.022,0,0,1,5.774,5.6a4.6,4.6,0,0,1,.87-1.315A3.6,3.6,0,0,1,9.295,3.156a4.976,4.976,0,0,1,.87.067,3.84,3.84,0,0,1,.675.184,3.794,3.794,0,0,1,.56.268c.171.1.342.2.513.308l-.423,4.409a1.332,1.332,0,0,0,.013.516.7.7,0,0,0,.174.318.635.635,0,0,0,.282.166,1.13,1.13,0,0,0,.99-.177,1.956,1.956,0,0,0,.531-.612,3.709,3.709,0,0,0,.357-.92,5.757,5.757,0,0,0,.156-1.146,6.665,6.665,0,0,0-.2-2.142,4.1,4.1,0,0,0-.874-1.657,4.048,4.048,0,0,0-1.567-1.067A6.2,6.2,0,0,0,9.07,1.293a5.477,5.477,0,0,0-2.257.446A5.129,5.129,0,0,0,5.1,2.976,5.8,5.8,0,0,0,3.974,4.837,7.694,7.694,0,0,0,3.5,7.155a7.02,7.02,0,0,0,.241,2.366,4.145,4.145,0,0,0,.953,1.7,4.006,4.006,0,0,0,1.615,1.018,6.771,6.771,0,0,0,2.229.338A8.389,8.389,0,0,0,10,12.43c.241-.044.47-.1.688-.154a5.535,5.535,0,0,0,.592-.192l.306,1.248a3.547,3.547,0,0,1-.663.3,6.89,6.89,0,0,1-.783.206A8.4,8.4,0,0,1,8.506,14a9.384,9.384,0,0,1-2.983-.436,5.565,5.565,0,0,1-2.175-1.3,5.327,5.327,0,0,1-1.305-2.141,8.327,8.327,0,0,1-.365-2.972A8.332,8.332,0,0,1,2.305,4.3,6.971,6.971,0,0,1,3.816,2.037a6.912,6.912,0,0,1,2.3-1.5A7.859,7.859,0,0,1,9.106,0,8.223,8.223,0,0,1,12,.47a5.662,5.662,0,0,1,2.088,1.321,5.3,5.3,0,0,1,1.239,2.041A7.119,7.119,0,0,1,15.66,6.462Zm-8.285.857a2.719,2.719,0,0,0,.17,1.476.88.88,0,0,0,.839.5,1.012,1.012,0,0,0,.287-.048A1.052,1.052,0,0,0,9,9.065,1.65,1.65,0,0,0,9.338,8.7a2.7,2.7,0,0,0,.309-.621l.33-3.426a2.162,2.162,0,0,0-.513-.061,1.925,1.925,0,0,0-.854.178,1.632,1.632,0,0,0-.6.521,2.917,2.917,0,0,0-.4.857,6.663,6.663,0,0,0-.228,1.172Z" transform="translate(-1.67)" fill="currentColor" /></svg>,

    phoneIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16.971" height="16.971" viewBox="0 0 16.971 16.971"><path d="M9.657,8.485c-.8.8-.8,1.6-1.6,1.6s-1.6-.8-2.4-1.6-1.6-1.6-1.6-2.4.8-.8,1.6-1.6-1.6-3.2-2.4-3.2-2.4,2.4-2.4,2.4c0,1.6,1.644,4.844,3.2,6.4s4.8,3.2,6.4,3.2c0,0,2.4-1.6,2.4-2.4s-2.4-3.2-3.2-2.4Z" transform="translate(8.788 -1.515) rotate(45)" fill="currentColor" /></svg>,

    reorderIcon: <svg xmlns="http://www.w3.org/2000/svg" width="18.001" height="10" viewBox="0 0 18.001 10"><path d="M-5860.5-1715v-1.25h18v1.25Zm0-4.374v-1.25h18v1.25Zm0-4.376V-1725h18v1.25Z" transform="translate(5860.5 1725) " fill="currentColor" /></svg>,

    fileShareIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17.066" viewBox="0 0 16 17.066"><path d="M136.6,7125.934a2.123,2.123,0,0,1,.254-1.011L135,7123.534v.833a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5V7114.7a.5.5,0,0,1,.5-.5h4.167v2.833a.5.5,0,0,0,.5.5H135V7119h3.8a2.135,2.135,0,1,1,0,1.067H135v2.133l2.582,1.937a2.133,2.133,0,1,1-.983,1.8Zm-7.6-4.149v.166a.251.251,0,0,0,.25.25h3.5a.251.251,0,0,0,.25-.25v-.166a.251.251,0,0,0-.25-.25h-3.5A.251.251,0,0,0,129,7121.784Zm0-1.334v.166a.251.251,0,0,0,.25.25h3.5a.251.251,0,0,0,.25-.25v-.166a.251.251,0,0,0-.25-.25h-3.5A.251.251,0,0,0,129,7120.45Zm0-1.333v.166a.251.251,0,0,0,.25.25h3.5a.251.251,0,0,0,.25-.25v-.166a.251.251,0,0,0-.25-.25h-3.5A.251.251,0,0,0,129,7119.117Zm3.333-2.25V7114.2h.128a.5.5,0,0,1,.353.145l1.02,1.022,2.847-1.646a2.138,2.138,0,1,1,.473.847l-2.611,1.51.31.311a.5.5,0,0,1,.147.351v.127Z" transform="translate(-127.001 -7111)" fill="currentColor"/></svg>,

    linkIcon: <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path d="M0,0V13H13V9.75H11.375v1.625H1.625V1.625H3.25V0ZM6.5,0,8.938,2.438,4.875,6.5,6.5,8.125l4.063-4.062L13,6.5V0Z" fill="currentColor"/></svg>,

    arrowRightIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10"><path d="M10,0V4H0V6H10v4l6-5.06Z" fill="currentColor"/></svg>,

    exclamationCircleIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001"><path d="M-2794,14121a8.01,8.01,0,0,1-8-8,8.007,8.007,0,0,1,8-8,8.009,8.009,0,0,1,8,8A8.012,8.012,0,0,1-2794,14121Zm0-6.287a.859.859,0,0,0-.857.857.859.859,0,0,0,.857.857.859.859,0,0,0,.858-.857A.859.859,0,0,0-2794,14114.714Zm0-5.143a.859.859,0,0,0-.857.857V14113a.859.859,0,0,0,.857.858.859.859,0,0,0,.858-.858v-2.569A.859.859,0,0,0-2794,14109.571Z" transform="translate(2801.999 -14105)" fill="currentColor"/></svg>,
    
    dotMenuicon: <svg xmlns="http://www.w3.org/2000/svg" width="5" height="15.998" viewBox="0 0 4.207 15.998"><path d="M-31110.775,2323.891a2.107,2.107,0,0,1,2.107-2.1,2.1,2.1,0,0,1,2.1,2.1,2.1,2.1,0,0,1-2.1,2.108A2.108,2.108,0,0,1-31110.775,2323.891Zm0-5.887a2.108,2.108,0,0,1,2.107-2.108,2.1,2.1,0,0,1,2.1,2.108,2.1,2.1,0,0,1-2.1,2.1A2.1,2.1,0,0,1-31110.775,2318Zm0-5.9a2.108,2.108,0,0,1,2.107-2.108,2.1,2.1,0,0,1,2.1,2.108,2.1,2.1,0,0,1-2.1,2.1A2.106,2.106,0,0,1-31110.775,2312.109Z" transform="translate(31110.775 -2310.001)" fill="currentColor"/></svg>,

    uploadIcon: <svg xmlns="http://www.w3.org/2000/svg" width="13.999" height="13.999" viewBox="0 0 13.999 13.999"><path d="M-3887.166,10578.5a2.335,2.335,0,0,1-2.334-2.333v-2.666a1,1,0,0,1,1-1,1,1,0,0,1,1,1v2.666a.335.335,0,0,0,.334.334h9.332a.335.335,0,0,0,.334-.334v-2.666a1,1,0,0,1,1-1,1,1,0,0,1,1,1v2.666a2.335,2.335,0,0,1-2.333,2.333Zm3.666-5v-5.588l-1.627,1.627a1,1,0,0,1-1.412,0,1,1,0,0,1,0-1.415l3.332-3.332.036-.033.01-.009.028-.023.009-.007a.289.289,0,0,1,.03-.023l.005,0,.035-.025h0a1,1,0,0,1,.454-.161h.009l.039,0h.05l.051,0h.016l.034,0,.018,0,.032,0,.014,0,.035.007.009,0,.04.009h0a1,1,0,0,1,.458.261l3.332,3.332a1,1,0,0,1,0,1.415.988.988,0,0,1-.706.292.989.989,0,0,1-.706-.292l-1.627-1.626v5.587a1,1,0,0,1-1,1A1,1,0,0,1-3883.5,10573.5Z" transform="translate(3889.5 -10564.501)" fill="currentColor"/></svg>,

    invoiceIcon: <svg xmlns="http://www.w3.org/2000/svg" width="41.997" height="42" viewBox="0 0 41.997 42"><path d="M35.361,1H8.636a1.909,1.909,0,0,0-1.241.477,7.636,7.636,0,0,1,5.059,6.395,3.341,3.341,0,0,1,0,.764V41.088a1.906,1.906,0,0,0,3.15,1.451l5.441-4.658,5.441,4.658a1.909,1.909,0,0,0,2.482,0l5.441-4.658,5.441,4.658A1.909,1.909,0,0,0,41.088,43a2.023,2.023,0,0,0,.8-.172A1.909,1.909,0,0,0,43,41.088V8.636A7.636,7.636,0,0,0,35.361,1ZM33.452,27.725H22a1.909,1.909,0,0,1,0-3.818H33.452a1.909,1.909,0,1,1,0,3.818Zm0-7.636H22a1.909,1.909,0,0,1,0-3.818H33.452a1.909,1.909,0,1,1,0,3.818Zm0-7.636H22a1.909,1.909,0,0,1,0-3.818H33.452a1.909,1.909,0,1,1,0,3.818ZM8.636,8.636V23.907H2.909A1.909,1.909,0,0,1,1,22V7.872a3.837,3.837,0,1,1,7.636.764Z" transform="translate(-1 -1)" fill="currentColor"/></svg>,

    paymentIcon: <svg xmlns="http://www.w3.org/2000/svg" width="44.003" height="44" viewBox="0 0 44.003 44"><path d="M-13813.845,18180.375l-6.438-11.15a1.247,1.247,0,0,1,.456-1.707l6.3-3.637a1.247,1.247,0,0,1,1.706.457l6.438,11.15a1.247,1.247,0,0,1-.456,1.707l-6.3,3.637a1.251,1.251,0,0,1-.624.168A1.249,1.249,0,0,1-13813.845,18180.375Zm9.637-5.945-5.48-9.49a9.458,9.458,0,0,1,8.177-4.992,8.54,8.54,0,0,1,5.1,1.676,8.645,8.645,0,0,1,1.158,1.021h5.225a1.805,1.805,0,0,1,1.816,1.7,1.776,1.776,0,0,1-1.775,1.848h-6.862a1.341,1.341,0,0,0-1.337,1.342,1.339,1.339,0,0,0,1.337,1.342h6.416a4.842,4.842,0,0,0,2.6-.748,5.4,5.4,0,0,0,.565-.41c.388-.283,5.288-3.814,7.839-5.654a1.878,1.878,0,0,1,2.624.43,1.879,1.879,0,0,1-.429,2.623s-6.032,4.4-8.98,6.52c-.707.508-1.354.922-1.962,1.273a11.55,11.55,0,0,1-5.754,1.52Zm-1.169-26.117a11.324,11.324,0,0,1,11.312-11.312,11.327,11.327,0,0,1,11.312,11.313,11.326,11.326,0,0,1-11.312,11.311A11.324,11.324,0,0,1-13805.377,18148.313Zm7.178,3.213a3.491,3.491,0,0,0,1.054,2.49,3.5,3.5,0,0,0,1.739.945v.3a1.338,1.338,0,0,0,1.341,1.342,1.337,1.337,0,0,0,1.337-1.342v-.314a3.512,3.512,0,0,0,1.784-.973,3.523,3.523,0,0,0,1.018-2.51v-.014a3.55,3.55,0,0,0-1.433-2.814l-2.706-2.008-1.113-.82a.868.868,0,0,1-.352-.689.86.86,0,0,1,.858-.857h1.2a.856.856,0,0,1,.857.857,1.34,1.34,0,0,0,1.342,1.336,1.336,1.336,0,0,0,1.337-1.336,3.538,3.538,0,0,0-2.793-3.459v-.311a1.336,1.336,0,0,0-1.337-1.336,1.336,1.336,0,0,0-1.341,1.336v.311a3.538,3.538,0,0,0-2.8,3.459,3.563,3.563,0,0,0,1.433,2.842l2.71,2,1.113.826a.853.853,0,0,1,.347.68v.014a.855.855,0,0,1-.853.861c-.735.006-.461.006-1.2.01a.859.859,0,0,1-.862-.854,1.341,1.341,0,0,0-1.337-1.332h-.01A1.344,1.344,0,0,0-13798.2,18151.525Z" transform="translate(13820.452 -18137)" fill="currentColor"/></svg>,

    fillFormIcon: <svg xmlns="http://www.w3.org/2000/svg" width="12.639" height="14"><g fill="currentColor"><path d="M1.999 6.5h4.07v1h-4.07Z" /><path d="M1.999 2.5h6.32v1h-6.32Z" /><path d="M1.999 10.5h4.07v1h-4.07Z" /><path d="M12.023 1.998a1 1 0 0 0-1.308.539L10.523 3l1.846.767.192-.462a1 1 0 0 0-.538-1.307Z" /><path d="m7.726 9.85.309 1.211a.25.25 0 0 0 .369.153l1.076-.635a.25.25 0 0 0 .1-.119l.288-.693L8.026 9l-.288.693a.25.25 0 0 0-.012.157Z" /><path d="m8.215 8.541 2.112-5.077 1.846.768-2.112 5.077Z" /><path d="m9.877 10.925-1.794 1.059a.25.25 0 0 1-.369-.154L7.2 9.811a.25.25 0 0 1 .012-.158l2.787-6.7V.25a.25.25 0 0 0-.25-.25h-9.5a.25.25 0 0 0-.25.25v13.5a.25.25 0 0 0 .25.25h9.5a.25.25 0 0 0 .25-.25v-2.983a.284.284 0 0 1-.12.158ZM1.499 2.25a.25.25 0 0 1 .25-.25h6.819a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25H1.749a.25.25 0 0 1-.25-.25Zm5.069 9.5a.25.25 0 0 1-.25.25H1.749a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h4.569a.25.25 0 0 1 .25.25Zm0-4a.25.25 0 0 1-.25.25H1.749a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h4.569a.25.25 0 0 1 .25.25Z" /></g></svg>,
    
    sharepointIcon: <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1153 0.734375C19.813 0.734375 23.6212 4.54253 23.6212 9.24026C23.6212 13.938 19.813 17.7462 15.1153 17.7462C10.4175 17.7462 6.60938 13.938 6.60938 9.24026C6.60938 4.54253 10.4175 0.734375 15.1153 0.734375Z" fill="white" stroke="#162865" strokeWidth="0.75" strokeMiterlimit="10"/><path d="M23.4861 8.9375C27.6356 8.9375 30.999 12.3015 30.999 16.4522C30.999 20.603 27.635 23.9651 23.4861 23.9651C19.3373 23.9651 15.9727 20.6011 15.9727 16.4522C15.9727 12.3034 19.336 8.9375 23.4861 8.9375Z" fill="white" stroke="#162865" strokeWidth="0.75" strokeMiterlimit="10"/><path d="M16.454 16.668C19.9082 16.668 22.7088 19.4686 22.7088 22.9228C22.7088 26.377 19.9082 29.1776 16.454 29.1776C12.9998 29.1776 10.1992 26.3776 10.1992 22.9228C10.1992 19.4679 12.9992 16.668 16.454 16.668Z" fill="white" stroke="#162865" strokeWidth="0.75" strokeMiterlimit="10"/><path d="M1.97005 22.9071H14.9156C15.5888 22.9071 16.1402 22.3563 16.1402 21.6825V8.73568C16.1402 8.0625 15.5888 7.51172 14.9156 7.51172H1.97005C1.29688 7.51172 0.746094 8.0625 0.746094 8.73568V21.6825C0.746094 22.3557 1.29688 22.9071 1.97005 22.9071Z" fill="white" stroke="#162865" strokeWidth="0.75" strokeMiterlimit="10"/><path d="M5.53125 13.3781C5.53125 15.1478 7.18549 15.5244 8.52743 16.1951C10.4113 17.1364 9.48956 17.9875 7.92807 17.9875C6.86814 17.9875 5.86879 17.3093 5.53188 17.2197V18.9263C6.87697 19.636 11.3514 20.0884 11.3514 17.0487C11.3514 14.8254 8.7943 14.6355 7.76782 13.9648C6.3243 13.0223 8.52364 11.7705 10.9236 13.0368V11.3302C9.09966 10.9068 5.53188 10.7775 5.53188 13.3781H5.53125Z" fill="#162865"/></svg>,

    profileIcon: <svg xmlns="http://www.w3.org/2000/svg" width="12.639" height="14" fill="currentColor"><g><circle cx="6.32" cy="4.5" r="3.5" /><path d="M0 13.25c0-2.897 2.616-5.25 6.32-5.25s6.32 2.353 6.32 5.25v.75h-12.64v-.75Z" /></g></svg>,
      
    clearIcon: <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z" fill="currentColor"></path></svg>
}