import { Col, Container } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";

const NOTFOUNDController = () => {
    return (
        <section className="d-flex flex-column flex-grow-1">
            <Container className="py-4 my-auto">
                <Col className="m-auto text-center max-w-450">
                    <FaExclamationTriangle size="4rem" className="text-danger mb-3" />
                    <h2 className="fs-2 fw-bold text-danger">Error! Page Not Found!</h2>
                    <p className="mb-0 fs-6">It appears that you are trying to access a page that does not exists. Please check the URL or click on a link which exists. Still&nbsp;if&nbsp;you&nbsp;find&nbsp;any issue, please contact admin.</p>
                </Col>
            </Container>
        </section>
    )
}
export default NOTFOUNDController;