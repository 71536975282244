import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { LIST_CRR_PAGE, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    allCasesData: [],
    loading: false,
};

//Get All Cases List Data
export const getAllCasesListAction = createAsyncThunk(
    "companyAllCasesSlice/getAllCasesListAction",
    async (value: any) => {
        try {
            // const apiUrl = `${EndPoint.COMPANY_MANAGEMENT_ALL_CASES_LIST_API}?company_id=${value.companyId}&search=${value.search}`
            const apiUrl = `${EndPoint.COMPANY_MANAGEMENT_ALL_CASES_LIST_API}`
            const response = await axiosRequest.post(apiUrl, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const companyAllCasesSlice = createSlice({
    name: 'All Cases',
    initialState: initialState,
    reducers: {
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Listing of Alerts
            .addCase(getAllCasesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCasesListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllCasesListAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});


export const companyAllCasesModuleActions = companyAllCasesSlice.actions;

export default companyAllCasesSlice.reducer;