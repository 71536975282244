import * as yup from "yup";

/**
 * SearchSchema for Validation
 * @date 9/1/2023 - 12:03:02 PM
 *
 * @type {*}
 */

export const SearchSchema = yup.object().shape({
  search: yup.string()
});
