import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { LIST_CRR_PAGE, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    alertsData: [],
    loading: false,
    particularClientObj: {},
    listObj: {
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STEPS_LIST_ROWS_DEF,
    },
};

// Add Alerts
export const addAlertsListAction = createAsyncThunk(
    "companyAlertsSlice/addAlertsListAction",
    async (data: any) => {
        try {
            const response = await axiosRequest.post(`${EndPoint.COMPANY_MANAGEMENT_ALERT_ADD_API}`, data.values);
            return response;

        } catch (error: any) {

            return error;

        }
    }
);

//Get Alerts List Data
export const getAlertsListAction = createAsyncThunk(
    "companyAlertsSlice/getAlertsListAction",
    async (value: any) => {
        try {
            const apiUrl = `${EndPoint.COMPANY_MANAGEMENT_ALERT_LIST_API}?company_id=${value.companyId}&search=${value.search}`
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//Update Alerts Status 
export const updateAlertsStatus = createAsyncThunk(
    "companyAlertsSlice/updateAlertsStatus",
    async (value: any) => {
        const data: any = value.values
        try {
            const response = await axiosRequest.put(`${EndPoint.COMPANY_MANAGEMENT_ALERT_STATUS_UPDATE_API}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//Edit Alert 
export const editAlertsListAction = createAsyncThunk(
    "companyAlertsSlice/editAlertsListAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.COMPANY_MANAGEMENT_ALERT_LIST_EDIT_API}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//Delete Alerts List Data 
export const deleteAlertsListAction = createAsyncThunk(
    "companyAlertsSlice/deleteAlertsListAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.COMPANY_MANAGEMENT_ALERT_LIST_DELETE_API}/${value.alertId}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const companyAlertsSlice = createSlice({
    name: 'alerts',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Add Alerts
            .addCase(addAlertsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addAlertsListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addAlertsListAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Listing of Alerts
            .addCase(getAlertsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAlertsListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getAlertsListAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Edit Alerts Listing
            .addCase(updateAlertsStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAlertsStatus.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateAlertsStatus.rejected, (state, action) => {
                state.loading = false;
            })

            // Delete Alerts from Listing
            .addCase(deleteAlertsListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAlertsListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteAlertsListAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});


export const alertsModuleActions = companyAlertsSlice.actions;

export default companyAlertsSlice.reducer;