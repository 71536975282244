import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { IoIosArrowDown, IoMdCalendar } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../../assets/images/logo.svg";
import profileImg from "../../../../assets/images/profile-image-dummy.jpg";
import { DATE_FORMAT_EMAIL_REPLY, POP_UP_CLOSE_2000, TEAMS_LIST_ROWS_DEF, VIEW } from "../../../../constants/global";
import { baseRoutes } from "../../../../routes/base-routes";
import { downloadSharePointFile } from "../../../../service/graph-api/share-point/share-point-file-download";
import { persistor } from "../../../../store";
import HeaderSearch from "./HeaderSearch";
import "./header.scss";
import LogoutHandleModal from "./HandleLogoutModal";
import Loader from "../../Loader";
import { notificationLatestAction } from "../../../../redux/notificationModule/notificationSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import moment from "moment";
import { logoutAction } from "../../../../redux/auth/authSlice";
export const Header = ({ toggleSidebarButton, isActiveSidebar }: any) => {
  const { instance, accounts } = useMsal();
  const authDetails = useSelector((state: any) => state.authSlice.authData);
  const [profileImage, setProfileImage] = useState(profileImg);
  const [fullName, setFullName] = useState("");
  const [openLogoutModalGlobalState, setOpenLogoutModalGlobalState] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isLoading = useSelector((state: any) => state.notificationSlice.loading);
  const notificationLatestData = useSelector((state: any) => state.notificationSlice.notificationLatestData)
  const notificationLatestRecords: any = notificationLatestData === null || notificationLatestData === undefined ? [] : notificationLatestData.records;

  useEffect(() => {
    if (authDetails) {
      console.log("From header authDetails", authDetails);
      downloadFile(authDetails);
      if (authDetails?.jwt_token) getNotificationLatestAction();
    }
  }, [authDetails]);
  useEffect(() => {

    // Respond to the `storage` event
    function storageEventHandler(event: any) {
      if (window.localStorage.getItem("checklogout") === 'forceLogout') {
        console.log("storageEventHandler", event);
        setOpenLogoutModalGlobalState(true);
        localStorage.removeItem("checklogout")
      }
    }
    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler);
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener("storage", storageEventHandler);
    };
  }, [])

  // GETTING LATEST NOTIFICATION LIST    
  const getNotificationLatestAction = () => {
    const notificationPayload = {
      rows: TEAMS_LIST_ROWS_DEF,
    };
    dispatch(notificationLatestAction(notificationPayload));
  };

  const downloadFile = (authDetails: any) => {
    setFullName(authDetails.full_name);
    if (authDetails.profile_pic && authDetails.profile_pic !== "") {
      downloadSharePointFile(instance, accounts, authDetails.profile_pic).then((response) => {
        const outObj = response["@microsoft.graph.downloadUrl"];
        if (outObj) {
          const downloadURL = outObj;
          setProfileImage(!downloadURL ? profileImg : downloadURL)
        } else if (response["error"]) {
          const errorObj = response["error"]["code"];
          toast.error(errorObj, { autoClose: POP_UP_CLOSE_2000 });
          setProfileImage(profileImg)
        }
      })
    } else {
      setProfileImage(profileImg)
    }

  }
  const handleCloseModal = () => {
    setOpenLogoutModalGlobalState(false);
    handleLogout("redirect")
    // instance.logoutRedirect({
    //   postLogoutRedirectUri: "/",
    // });
  }
  const handleLogout = async (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      dispatch(logoutAction()).then(async () => {
        await persistor.purge();
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      });
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Navbar
        bg="white"
        variant="light"
        className="py-0 px-md-1 shadow-sm z-2 theme-top-header"
        data-bs-theme="light"
      >
        <Container fluid className="h-100">
          <Button
            onClick={toggleSidebarButton}
            variant="link"
            className="align-items-center d-flex d-xl-none justify-content-center me-3 navMenuBtn p-0 py-3"
          >
            <span
              className={`bg-primary d-inline-block menuTrigger position-relative text-center ${isActiveSidebar ? "active" : ""
                }`}
            ></span>
          </Button>
          <Link to={baseRoutes.dashboard.path} className="me-3">
            <Image
              className="img-fluid"
              src={Logo}
              alt="Logo"
              width={153}
              height={35}
            />
          </Link>
          <Nav className="ms-auto align-items-center order-last">
            <Link
              to={baseRoutes.calendar.path}
              className="ms-2 me-lg-3 me-1 text-body p-2 d-none d-md-inline-block"
            >
              <IoMdCalendar size={27} />
              <span className="visually-hidden">Calendar</span>
            </Link>
            <Dropdown className="notificationDropdown me-lg-3 me-1">
              <Dropdown.Toggle
                variant="link"
                id="dropdown-notification"
                className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-body p-2"
              >
                <FaBell size={22} className={notificationLatestData?.totalrecord > 0 ? 'ring' : ''} />
                <Badge
                  bg="danger"
                  pill
                  className="align-items-center border border-white customBadge d-flex fs-11 fw-normal justify-content-center mt-1 p-1 position-absolute rounded-pill start-100"
                >
                  {notificationLatestData?.totalrecord > 9 ? (
                    <span>9+</span>
                  ) : (
                    <span>{notificationLatestData?.totalrecord}</span>
                  )}

                  <span className="visually-hidden">Unread Notifications</span>
                </Badge>
              </Dropdown.Toggle>

              <Dropdown.Menu align="end" className="shadow border-0 mt-3 p-1 theme-notification-menu">
                <ul className="list-unstyled p-1 theme-custom-scrollbar overflow-auto">
                  {notificationLatestRecords?.map((dataItems: any) => {
                    return (
                      <li
                        key={dataItems?.notification_id}
                        className="fs-15 pb-2 mb-2 border-bottom"
                      >
                        {dataItems?.notification_data ? (
                          <Link
                            to={`${baseRoutes.caseManagement.path}/${VIEW}/${dataItems?.notification_data?.case_id}?type=Steps Due Date`}
                            className={dataItems?.read_status ? "" : "fw-bold"}
                          >
                            {dataItems?.notification_title}
                          </Link>
                        ) : (
                          <div
                            className={dataItems?.read_status ? "" : "fw-bold"}
                          >
                            {dataItems?.notification_title}
                          </div>
                        )}

                        <div className="fs-12 text-body-tertiary">
                          {moment(dataItems?.created_at).format(
                            DATE_FORMAT_EMAIL_REPLY
                          )}
                        </div>
                      </li>
                    );
                  })}
                  {notificationLatestRecords?.length === 0 &&
                    <li className="fs-15 pb-2 mb-2 border-bottom text-center">No data available</li>
                  }
                </ul>
                <Dropdown.Item
                  as={NavLink}
                  to={baseRoutes.notification.path}
                  className="p-1 fs-12 fw-semibold bg-transparent text-center text-light-emphasis"
                >
                  View All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="profileDropdown ms-1">
              <Dropdown.Toggle
                variant="link"
                id="dropdown-profile"
                className="border-0 fw-semibold text-decoration-none p-0 text-body"
              >
                <Image
                  className="object-fit-cover rounded-circle border border-light-subtle"
                  src={profileImage}
                  alt="Profile Image"
                  width={40}
                  height={40}
                />
                <div className="align-middle fs-6 d-none d-md-inline-block ms-1 px-2 text-truncate max-w-150 text-capitalize">
                  {fullName?.toLowerCase()}
                </div>
                <IoIosArrowDown size={16} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end" className="border-0 shadow">
                <Dropdown.Item as={NavLink} to="/app/profile">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to={baseRoutes.reminder.path}
                  disabled={false}
                >
                  Reminder
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <HeaderSearch />
        </Container>
      </Navbar>
      <LogoutHandleModal
        show={openLogoutModalGlobalState}
        handleClose={handleCloseModal}
      />
    </>
  );
};
