import {
  FaBuilding,
  FaFileAlt,
  FaRegCircle,
  FaUserCircle
} from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import {
  MdBook,
  MdDashboard,
  MdHandshake,
  MdLibraryBooks
} from "react-icons/md";
import appConfig from "../../../../helpers/config";
import { SvgIcons } from "../../SvgIcons";
const basePath = appConfig.basePath;
export const NavItems = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: <MdDashboard size={21} />,
    path: `${basePath}/dashboard`,
  },
  {
    id: 2,
    menuName: "Client Management",
    menuIcon: <MdHandshake size={23} />,
    path: `${basePath}/client-management`,
    disabled: false,
  },
  {
    id: 3,
    menuName: "Case Management",
    menuIcon: SvgIcons.caseManagementIcon,
    path: `${basePath}/case-management`,
    disabled: false,
  },
  {
    id: 4,
    menuName: "Manage Case Types",
    menuIcon: <MdLibraryBooks size={22} />,
    path: `${basePath}/manage-case-types`
  },
  {
    id: 5,
    menuName: "Knowledge Base",
    menuIcon: <MdBook size={20} />,
    path: `${basePath}/knowledge-base`,
    hideInMobile: true,
    items: [
      {
        id: 51,
        menuName: "Stages",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-stages`,
        hideInMobile: false,
      },
      {
        id: 52,
        menuName: "Steps",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-steps`,
      },
      {
        id: 53,
        menuName: "Doc Checklist Items",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-doc-checklist-items`,
      },
      {
        id: 54,
        menuName: "Document Checklist",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-document-checklist`,
        // disabled: true,
      },
      {
        id: 55,
        menuName: "Document Types",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-document-types`,
        disabled: false,
      },
      {
        id: 56,
        menuName: "Documents",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-documents`,
        disabled: false,
      },
      {
        id: 57,
        menuName: "Questions",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-questions`,
        disabled: false,
      },
      {
        id: 58,
        menuName: "Questionnaires",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-questionnaires`,
        disabled: false,
      },
      {
        id: 59,
        menuName: "Forms",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-forms`,
        disabled: false,
      },
      {
        id: 60,
        menuName: "Email Templates",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/knowledge-base-email-templates`,
        disabled: false,
      },
    ],
  },
  {
    id: 6,
    menuName: "User Management",
    menuIcon: <FaUserCircle size={16} />,
    path: `${basePath}/user-management`,
    items: [
      {
        id: 61,
        menuName: "Users List",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/user-management`,
        disabled: false,
      },
      {
        id: 62,
        menuName: "Manage Designations",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/manage-designations`,
        disabled: false,
      },
      {
        id: 63,
        menuName: "Roles & Permissions",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/roles-and-permissions`,
        disabled: false,
        hideInMobile: true,
      },
      {
        id: 64,
        menuName: "Manage Teams",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/manage-teams`,
        disabled: false,
      },
    ],
  },

  {
    id: 7,
    menuName: "Settings",
    menuIcon: <IoMdSettings size={19} />,
    path: `${basePath}/settings`,
    disabled: false,
  },
  {
    id: 8,
    menuName: "Reports",
    menuIcon: <FaFileAlt size={16} />,
    path: `${basePath}/reports`,
    disabled: false,
    items: [
      {
        id: 81,
        menuName: "Saved Reports",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/saved-reports`,
        hideInMobile: false,
        disabled: false,
      },
      {
        id: 82,
        menuName: "Generate New Report",
        menuIcon: <FaRegCircle size={10} />,
        path: `${basePath}/generate-new-report`,
        hideInMobile: true,
        disabled: false,
      },
    ],
  },
  {
    id: 9,
    menuName: "Company Management",
    menuIcon: <FaBuilding size={14} />,
    path: `${basePath}/company-management`,
    disabled: false,
  },
];
