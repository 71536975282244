import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import EndPoint from '../../api/Endpoint';
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    authData: {},
    loading:false,
    profileData:{},
    initialSharePointFolders:[],
    KnowledgeBaseFolders: [],
    masterPermissionObj:[],
    resObjRolesPermissionUser:{}
};


export const getAuthTokensAction = createAsyncThunk(
    "authModule/getAuthTokensAction",
    async (value:string, thunkAPI) => {
        const authData = {
            email: value,
        };
        try {
            const response = await axiosRequest.post(`${EndPoint.USER_VERIFICATION}`, authData);
            return response;
        } catch (error:any) {
            const errorRsp = error.response;

            const status = error && errorRsp ? errorRsp.status : "";

            if (status !== 200) {
                toast.error(API_CUSTOM_MESSAGE.SOMETHING_WRONG, { autoClose: 1000 });
            }


            return error;

        }
    }
);

export const getProfileAction = createAsyncThunk(
    "authModule/getProfileAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.GET_USER_PROFILE}?outlookUserId=${value}`);
            return response;
        } catch (error:any) {

            return error;
        }
    }
);

export const editProfileAction = createAsyncThunk(
    "authModule/editProfileAction",
    async (value:any) => {
        const editData = {
            personal_detail: {
                first_name: value.first_name,
                last_name: value.last_name,
                profile_pic: value.profile_pic,
                phone_number: value.phone_number,
            },
            official_detail: {
                legal_first_name: value.attorney_first_name,
                legal_middle_name: value.attorney_middle_name,
                legal_last_name: value.attorney_last_name,
                uscis_online_account_number: value.attorney_account_number,
                licensing_authority: value.licensing_authority,
                bar_number: value.bar_number,
                subject_restricting: value.subject_restricting,
                contact_confirmation: value.contact_confirmation,
                attorney_forms_name: value.attorney_forms_name,
                practice_court: value.practice_court,
                practice_state: value.practice_state,
            },
            outlook_detail: {
                user_email: value.email,
                app_password: "1234666",
            },
            planner_record: value.plannerData,
            email_signature: value.email_signature,
        }

        console.log("######## Edit Profile ############", value);
        try {

            const response = await axiosRequest.put(`${EndPoint.GET_USER_PROFILE}`, editData);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

//LOGOUT API
export const logoutAction = createAsyncThunk(
    "authModule/logoutAction",
    async () => {
        try {
            const response = await axiosRequest.post(`${EndPoint.LOGOUT_API}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const authSlice:any = createSlice({
    name: 'auth module',
    initialState: initialState,
    reducers: {
        startLoader(state,actions) {
            state.loading = true;
        },
        stopLoader(state,actions) {
            state.loading = false;
        },
        setInitialFolders(state,actions) {
            state.loading = false;
            state.initialSharePointFolders = actions.payload;
        },
        setKBFolders(state,actions) {
            state.loading = false;
            state.KnowledgeBaseFolders = actions.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuthTokensAction.pending, (state) => {

                state.loading = true;
            })
            .addCase(getAuthTokensAction.fulfilled, (state, action) => {
                let respData = action.payload.data;
                state.loading = false;
                state.authData = respData;
                state.masterPermissionObj = action?.payload?.masterPermissionObj ? action?.payload?.masterPermissionObj :[];
                state.resObjRolesPermissionUser = action?.payload?.resObjRolesPermissionUser ? action?.payload?.resObjRolesPermissionUser : {};
            })

            .addCase(getAuthTokensAction.rejected, (state, action) => {

                state.loading = false;
            })
            .addCase(getProfileAction.pending, (state) => {

                state.loading = true;
            })
            .addCase(getProfileAction.fulfilled, (state, action) => {

                let respData = action.payload.data
                state.loading = false;
                state.profileData = respData;
            })

            .addCase(getProfileAction.rejected, (state, action) => {

                state.loading = false;
            })
            .addCase(editProfileAction.pending, (state) => {

                state.loading = true;
            })
            .addCase(editProfileAction.fulfilled, (state, action) => {

                console.log("##### editProfileAction #####", state, action.payload.data);
                state.authData = {...state.authData, full_name: action.payload.data?.full_name, profile_pic: action.payload.data?.profile_pic};
                state.loading = false;
            })

            .addCase(editProfileAction.rejected, (state, action) => {

                state.loading = false;
            })

            .addCase(logoutAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(logoutAction.fulfilled, (state, action) => {
                state.loading = false;
                state.authData = {};
                state.masterPermissionObj = [];
                state.resObjRolesPermissionUser = {};
            })
            .addCase(logoutAction.rejected, (state, action) => {
                state.loading = false;
                state.authData = {};
                state.masterPermissionObj = [];
                state.resObjRolesPermissionUser = {};
            })
    }
});

export const authSliceActions = authSlice.actions;

export default authSlice.reducer;