import { EventInfo } from "ckeditor5";
import EndPoint from "../../../api/Endpoint";
import appConfig from "../../../helpers/config";
let store: any;

interface MyCustomUploadAdapterPluginSignatureOptions {
  handleChange: (data: any) => void;
}

export const injectStoreUploadImageEditor = (_store: any) => {
  store = _store;
};
const baseUrlApi = appConfig.apiEndPoint;

class MyCustomUploadAdapter {
  loader: any;
  maxFileSize: any;
  maxWidth: any;
  maxHeight: any;
  reader: any;
  imageSrcc: any;

  constructor(loader: any, maxFileSize: any, maxWidth: any, maxHeight: any, src: any) {
    this.loader = loader;
    this.maxFileSize = maxFileSize;
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
    this.imageSrcc = src;
  }

  upload() {
    return this.loader.file.then(
      (file: any) =>
        new Promise((resolve, reject) => {

          // Check the file type
          const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
          if (!validTypes.includes(file.type)) {
            alert('Invalid file type. Only JPG, JPEG and PNG are allowed.');
            resolve({
              default: this.imageSrcc, // Assuming your server response contains the URL of the uploaded image
            });
            return;
          }

          // Check the file size
          if (file.size > this.maxFileSize) {
            alert(`File is too large. Maximum size is ${this.maxFileSize / (1024 * 1024)
              } MB.`)
            resolve({
              default: this.imageSrcc, // Assuming your server response contains the URL of the uploaded image
            });
            return;
          }
          // Check the aspect ratio
          const image = new Image();
          image.src = URL.createObjectURL(file);
          image.onload = () => {
            if (image.width > this.maxWidth || image.height > this.maxHeight) {
              alert(`Image dimensions are too large. Maximum size is ${this.maxWidth}x${this.maxHeight} pixels.`)
              resolve({
                default: this.imageSrcc, // Assuming your server response contains the URL of the uploaded image
              });
              return;
            }
            const data = new FormData();
            data.append("files", file);
            data.append("file_ype", file.type);
            let tokenBearer: any = "";

            const token = store.getState().authSlice.authData;
            const authToken =
              token === undefined || token === null ? "" : token.jwt_token;
            if (token) {
              tokenBearer = `Bearer ${authToken}`;
            }

            fetch(`${baseUrlApi}/${EndPoint.UPLOAD_BLOB_TEXT_EDITOR_API}`, {
              // Replace with your server's upload URL
              method: "POST",
              body: data,
              headers: {
                Authorization: tokenBearer,
              },
            })
              .then((response) => {
                if (!response.ok) {
                  return response.json().then((error) => {
                    reject(error);
                  });
                }
                return response.json();
              })
              .then((data) => {
                resolve({
                  default: data.url, // Assuming your server response contains the URL of the uploaded image
                });
              })
              .catch((error) => {
                console.error("Upload error:", error);
                reject(error);
              });
          };
        })
    );
  }

  abort() {
    // Handle the aborting of the upload process if needed
  }
}

export function MyCustomUploadAdapterPlugin(editor: any) {
  const maxFileSize = 1048576; // 1MB in bytes
  const maxWidth = 1200; // Maximum width in pixels
  const maxHeight = 1200; // Maximum height in pixels
  const viewDocument = editor.editing.view.document;
  let imageSrcc = '';
  // Listen for click events on the editor's view
  viewDocument.on('click', (event: any, data: any) => {
    // Get the clicked element in the view
    const clickedElement = data.domTarget;
    // Check if the clicked element is an image
    if (clickedElement.tagName == 'IMG') {
      // Get the image's source URL and other attributes
      const imageSrc = clickedElement.getAttribute('src');
      imageSrcc = imageSrc;
    } else {
      imageSrcc = '';
    }
  });

  let lastEventType: any = null;
  // Listen for keydown events (keyboard-triggered changes)
  viewDocument.on('keyup', (event: any, data: any) => {
    lastEventType = 'key';
  });
  // Listen for keydown events (keyboard-triggered changes)
  viewDocument.on('mousedown', (event: any, data: any) => {
    lastEventType = 'mouse';
  });

  // Listen for CKEditor changes
  viewDocument.on('change', (event: EventInfo<string, unknown>) => {
    const myFunct = editor.config.get('myCustomVar')
    // Reset the event type after change is processed
    lastEventType = null;
    myFunct({ htmlValue: editor?.getData() })
  });

  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return new MyCustomUploadAdapter(loader, maxFileSize, maxWidth, maxHeight, imageSrcc);
  };
}

export function MyCustomUploadAdapterPluginSignature(editor: any) {
  const maxFileSize = 1048576; // 1MB in bytes
  const maxWidth = 200; // Maximum width in pixels
  const maxHeight = 200; // Maximum height in pixels
  const viewDocument = editor.editing.view.document;
  let imageSrcc = '';
  let dontResizeImage = true;
  // Listen for click events on the editor's view
  viewDocument.on('click', (event: any, data: any) => {
    // Get the clicked element in the view
    const clickedElement = data.domTarget;
    // Check if the clicked element is an image
    if (clickedElement.tagName == 'IMG') {
      // Get the image's source URL and other attributes
      const imageSrc = clickedElement.getAttribute('src');
      imageSrcc = imageSrc;
    } else {
      imageSrcc = '';
    }
  });
  let lastEventType: any = null;
  // Listen for keydown events (keyboard-triggered changes)
  viewDocument.on('keyup', (event: any, data: any) => {
    lastEventType = 'key';
  });
  // Listen for keydown events (keyboard-triggered changes)
  viewDocument.on('mousedown', (event: any, data: any) => {
    const clickedElement = data.domTarget;
    // Check if the clicked element is inside a table cell (<td> or <th>)
    if (clickedElement.tagName == 'SPAN' || clickedElement.tagName == 'DIV' || clickedElement.tagName == 'P') {
      dontResizeImage = false;
    } else {
      dontResizeImage = true;
    }
    lastEventType = 'mouse';
  });

  // Listen for CKEditor changes
  viewDocument.on('change', (event: EventInfo<string, unknown>) => {
    const model = editor.model;
    const myFunct = editor.config.get('myCustomVar')
    const initialImageCheck = editor.config.get('initialImageCheck');
    let noImageSelection: any = true;
    const selection = model.document.selection;
    const selectedElement = selection.getSelectedElement();
    if (selectedElement) {
      // Use selected element name instead of is('image')
      if (selectedElement.name === 'image' || selectedElement.name === 'imageBlock') {
        const parentTable = selectedElement.findAncestor('table');
        if (parentTable) {
          // Prevent the whole table from being selected
          noImageSelection = false;
          model.change((writer: any) => {
            const range = model.createRangeOn(selectedElement);
            writer.setSelection(range);  // Focus only on the image, not the table
          });
        }
      }
    }
    if (lastEventType === 'key') {
      myFunct({ htmlValue: editor?.getData() })
    } else {

      let currentValue: string = editor?.getData();
      const parser = new DOMParser();
      const doc = parser.parseFromString(currentValue, 'text/html');
      const docReal: HTMLElement | null = document.getElementById("ckEditorCover");

      const updateImageDimensions = (elements: NodeListOf<Element>) => {
        elements.forEach(element => {
          const widthValue = element.getAttribute('style')?.match(/width:\s*(\d+)px/);
          const width = widthValue ? parseInt(widthValue[1], 10) : null;

          if (width) {
            const childImage = element.querySelector('img');
            if (childImage) {
              if (!initialImageCheck && noImageSelection && dontResizeImage) {
                childImage.setAttribute('width', `${width}`);
                childImage.setAttribute('height', `auto`);
                childImage.style.height = 'auto';
              }
            } else {
              if (element.tagName == "IMG") {
                element.setAttribute('width', `${width}`);
                element.setAttribute('height', `auto`);
                element.setAttribute('style', `width:${width}px;height:auto;`);
              }
            }
          }
        });
      };
      if (docReal) {
        const imageResizedReal = docReal.querySelectorAll('.image_resized');
        updateImageDimensions(imageResizedReal);
      }

      const imageResized = doc.querySelectorAll('.image_resized');
      updateImageDimensions(imageResized);

      currentValue = doc.body.innerHTML;
      myFunct({ htmlValue: currentValue });
    }
    // Reset the event type after change is processed
    if (initialImageCheck) {
      editor.config.set('initialImageCheck', false);
    }
    lastEventType = null;
  });

  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return new MyCustomUploadAdapter(loader, maxFileSize, maxWidth, maxHeight, imageSrcc);
  };
}