import { RouteObject } from "react-router-dom";
import { Login } from "../pages/auth/login/Login";

/**
 * Public Routes Available in App
 * @type {{}}
 */

export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "login",
    element: <Login />,
  },
];
