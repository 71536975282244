import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Header } from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
    const [isActiveSidebar, setIsActiveSidebar] = useState(false);

    const toggleSidebarButton = () => {
        setIsActiveSidebar((current) => !current);
    };

    return (
        <main className="mainContentBox vh-100">
            {isActiveSidebar ? (
                <div
                    onClick={toggleSidebarButton}
                    className="backdrop bg-black bg-opacity-25 bottom-0 position-fixed start-0 w-100"
                ></div>
            ) : null}
            <div className="d-flex flex-column h-100 w-100">
                <Header
                    toggleSidebarButton={toggleSidebarButton}
                    isActiveSidebar={isActiveSidebar}
                />
                <div className="flex-grow-1 overflow-hidden w-100 header-static-below-h-600">
                    <Row className="gx-0 h-100">
                        <Col xs="auto" className="h-100">
                            <Sidebar
                                isActiveSidebar={isActiveSidebar}
                                toggleSidebarButton={toggleSidebarButton}
                            />
                        </Col>
                        <Col xs className="d-flex flex-column mw-1 h-100 bg-body-tertiary">
                            {isActiveSidebar ? (
                                <div
                                    onClick={toggleSidebarButton}
                                    className="backdrop bg-black bg-opacity-25 bottom-0 position-fixed start-0 w-100"
                                ></div>
                            ) : null}
                            <Outlet />
                        </Col>
                    </Row>
                </div>
            </div>
        </main>
    );
};

export default MainLayout;