import { FC } from "react";
import { Button, Modal, Stack } from "react-bootstrap";

interface ILogoutHandleModal {
  show?: boolean;
  handleClose?: any;
}

/**
 * Rename Step Name Modal Component
 * @date 12/18/2023 - 12:11:19 PM
 *
 * @param {{ show: any; handleClose: any; }} {
  show,
  handleClose,
}
 * @returns {*}
 */

const LogoutHandleModal: FC<ILogoutHandleModal> = ({
  show,
  handleClose,
}) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      className="theme-modal"
    >
        <Modal.Header>
            <Modal.Title as="h6" className="fw-semibold">
                Unauthorized Access
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 pb-2 min-h-100">
          Unauthorized Access due to one of the following reasons:
            <ul className="list-style-position-inside list-style-disc">
              <li>Token expired: The login token is only valid for 24 hours.</li>
              <li>Your account has not yet been created on CHWizard.</li>
              <li>You have logged in using another browser.</li>
              <li>You have logged out from another tab in the same browsing window.</li>
              <li>There might be changes to your assigned access role.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="py-2">
            <Stack direction="horizontal" gap={3} className="flex-wrap py-1">
            <Button
                type="submit"
                variant="danger"
                className="min-w-80"
                onClick={handleClose}
            >
                Logout
            </Button>
            </Stack>
        </Modal.Footer>
    </Modal>
  );
};

export default LogoutHandleModal;
