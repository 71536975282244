import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    knowledgeBaseDocumentTypelistData: [],
    loading:false,
    particularDocChecklistObj:{},
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
        deletedDocTypeProp: false,
    }
};

export const getAllDocumentTypeAction = createAsyncThunk(
    "knowledgebaseDocumentTypeModule/getAllDocumentTypeAction",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.KB_DOC_CHK_ITEMS_ALL_RECORD_API}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
)

export const addNewDocumentTypeAction = createAsyncThunk(
    "knowledgebaseDocumentTypeModule/addNewDocumentTypeAction",
    async (value:any) => {
        // const addData:any = {
        //     type_name : value.title.trim(),
        //     description: value.description.trim(),
        //     allowed_file_types: value.allowed_file_types,
        //     sample_document_url:value.sample_document_url
        // }
        try {
            const response = await axiosRequest.post(`${EndPoint.KB_DOCUMENT_TYPE_ADD_API}`, value);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);


export const getKnowledgeBaseDocumentTypeListAction = createAsyncThunk(
    "knowledgebaseDocumentTypeModule/getKnowledgeBaseDocumentTypeListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.KB_DOCUMENT_TYPE_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedDocumentType=${value.deletedDocType}` : 
        `${EndPoint.KB_DOCUMENT_TYPE_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedDocumentType=${value.deletedDocType}`;
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const deleteDocumentTypeItemAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/deleteDocumentTypeItemAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.KB_DOCUMENT_TYPE_DELETE_API}/${value.itemId}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

export const getKnowledgeBaseDocTypesAction = createAsyncThunk(
    "knowledgebasedocchkitemsModule/getKnowledgeBaseDocTypesAction",
    async (value:any) => {
        const apiUrl = EndPoint.KB_DOCUMENT_TYPE_GET_API+`/${value}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const editNewDocumentTypeAction = createAsyncThunk(
    "knowledgebaseDocumentTypeModule/editNewDocumentTypeAction",
    async (value:any) => {
        // const addData:any = {
        //     id: value.id,
        //     type_name : value.title.trim(),
        //     description: value.description.trim(),
        //     allowed_file_types: value.allowed_file_types,
        //     sample_document_url:value.sample_document_url
        // }
        try {
            const response = await axiosRequest.put(`${EndPoint.KB_DOCUMENT_TYPE_EDIT_API}`, value);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

// RESTORE Document Type
export const restoreDocumentTypeAction = createAsyncThunk(
    "knowledgebaseDocumentTypeModule/restoreDocumentTypeAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.KB_DOCUMENT_TYPE_RESTORE_API}`, value);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);


const knowledgeBaseDocumentTypeSlice:any = createSlice({
    name: 'knowledgebaseDocumentTypes',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
            state.listObj.deletedDocTypeProp = actions.payload.deletedDocType;
        },
        startLoader(state,actions) {
            state.loading = true;
        },
        stopLoader(state,actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewDocumentTypeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewDocumentTypeAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addNewDocumentTypeAction.rejected, (state, action) => {
            state.loading = false;
            })
            .addCase(getAllDocumentTypeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDocumentTypeAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllDocumentTypeAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getKnowledgeBaseDocumentTypeListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseDocumentTypeListAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.knowledgeBaseDocumentTypelistData = data;
            })
            .addCase(getKnowledgeBaseDocumentTypeListAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(deleteDocumentTypeItemAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteDocumentTypeItemAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteDocumentTypeItemAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getKnowledgeBaseDocTypesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseDocTypesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getKnowledgeBaseDocTypesAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(editNewDocumentTypeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editNewDocumentTypeAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editNewDocumentTypeAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(restoreDocumentTypeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreDocumentTypeAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreDocumentTypeAction.rejected, (state, action) => {
               state.loading = false;
            })
            
    }
});

export const knowledgeBaseDocumentTypeActions = knowledgeBaseDocumentTypeSlice.actions;

export default knowledgeBaseDocumentTypeSlice.reducer;