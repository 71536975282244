import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import EndPoint from '../../api/Endpoint';
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, POP_UP_CLOSE_1000, POP_UP_CLOSE_2000, ROLE_DEF_COLUMN, ROLE_LIST_ROWS_DEF, STAGES_DEFAULT_SORT_COLUMN, STAGES_LIST_ROWS_DEF, SUCCESS_STATUS, SUCCESS_STATUS_ERROR } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';



const initialState = {
    rolesData: [],
    loading:false,
    rolesMasterData:[],
    roleDetailData:[],
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: ROLE_LIST_ROWS_DEF,
        sortFieldProp: ROLE_DEF_COLUMN
    }

};


export const getRolesListAction = createAsyncThunk(
    "roles&permissionModule/getRolesListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.ROLES_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}` : 
        `${EndPoint.ROLES_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}`;
        try {

            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;

        }
    }
);




export const getRolesMasterAction = createAsyncThunk(
    "roles&permissionModule/getRolesMasterAction",
    async (value:any) => {
        const apiUrl = EndPoint.ROLES_MASTER_API
        try {

            const response = await axiosRequest.get(apiUrl);
          
            return response;

        } catch (error:any) {
            return error;

        }
    }
);



export const deleteRoleAction = createAsyncThunk(
    "roles&permissionModule/deleteRoleAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.ROLES_DELETE_API}/${value.roleId}`);
            return response;
        } catch (error:any) {
                   
            return error;

        }
    }
);

export const addNewRoleAction = createAsyncThunk(
    "roles&permissionModule/addNewRoleAction",
    async (value:any) => {

const addData = {
    role : value.roleName,
    description : value.discreption,
    created_by:value.createdBy,
    rights :value.filtered
}
        try {
            const response = await axiosRequest.post(`${EndPoint.ROLES_ADD_API}`, addData);
            return response;
        } catch (error:any) {
       
            return error;

        }
    }
);



export const updateRoleAction = createAsyncThunk(
    "roles&permissionModule/updateRoleAction",
    async (value:any) => {

const editData = {
    id : value.roleId,
    role : value.roleName,
    description : value.discreption,
    updated_by:value.updatedBy,
    rights :value.filtered
}
        try {
            const response = await axiosRequest.put(`${EndPoint.ROLES_ADD_API}`, editData);
            return response;
        } catch (error:any) {
                   
            return error;

        }
    }
);




export const getRoleDetailAction = createAsyncThunk(
    "roles&permissionModule/getRoleDetailAction",
    async (value:any) => {

        try {
            const response = await axiosRequest.get(`${EndPoint.ROLES_DETAIL_API}/${value.roleId}`);
            return response;
        } catch (error:any) {
                
            return error;

        }
    }
);






const roleSlice = createSlice({
    name: 'roles & permission',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRolesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRolesListAction.fulfilled, (state, action) => {
                
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.rolesData = data;
            })

            .addCase(getRolesListAction.rejected, (state, action) => {
               state.loading = false;
            })

            .addCase(getRolesMasterAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRolesMasterAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.rolesMasterData = data;
            })

            .addCase(getRolesMasterAction.rejected, (state, action) => {
               state.loading = false;
            })

            .addCase(deleteRoleAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteRoleAction.fulfilled, (state, action) => {
                state.loading = false;
        
            })

            .addCase(deleteRoleAction.rejected, (state, action) => {
               state.loading = false;
            })

            .addCase(addNewRoleAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewRoleAction.fulfilled, (state, action) => {
                state.loading = false;
        
            })

            .addCase(addNewRoleAction.rejected, (state, action) => {
               state.loading = false;
            })

            .addCase(getRoleDetailAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRoleDetailAction.fulfilled, (state, action) => {
             
                let data = action.payload.data.rights ? action.payload.data.rights : []

                state.loading = false;
                state.roleDetailData = data;
        
            })

            .addCase(getRoleDetailAction.rejected, (state, action) => {
               state.loading = false;
            })


            .addCase(updateRoleAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateRoleAction.fulfilled, (state, action) => {
                state.loading = false;
        
            })

            .addCase(updateRoleAction.rejected, (state, action) => {
               state.loading = false;
            })

            
    }
});


export const roleActions = roleSlice.actions;

export default roleSlice.reducer;