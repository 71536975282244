 
 import { loginRequest, graphConfig } from "../../../helpers/auth-config";
 import appConfig from "../../../helpers/config";

 /**
  * Attaches a given access token to a MS Graph API call. Returns information about the user
  * @param accessToken 
  */
export async function downloadSharePointFile(instance:any, accounts:any, idOfFile:string) {    
    const headers = new Headers();
    const itemId = idOfFile;
    return instance
    .acquireTokenSilent({
         ...loginRequest,
         account: accounts[0],
    })
    .then(async(response:any) => {
         const bearer = `Bearer ${response.accessToken}`;
         const siteId = appConfig.siteId;
         headers.append("Authorization", bearer);
         const options = {
            method: "GET",
            headers: headers,
         };
         const url= graphConfig.graphAPIEndPoint+`sites/${siteId}/drive/items/${itemId}?$select=content.downloadUrl,id,name`;
         return await fetch(url, options)
         .then(response => response.json())
     }).catch((error:any) => {
          console.log("################################### error downloadSharePointFile ###################################", error)
          instance.logoutRedirect({
              postLogoutRedirectUri: "/",
          });
      });
}