import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    knowledgeBaseDocChecklistData: [],
    loading:false,
    particularDocChecklistObj:{},
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
        deletedDocChecklistProp: false
    }
};

export const getAllDocCheckListItemAction = createAsyncThunk(
    "knowledgebasedocchkModule/getAllDocCheckListItemAction",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.KB_DOC_CHK_ITEMS_ALL_RECORD_API}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
)

export const addNewDocCheckListAction = createAsyncThunk(
    "knowledgebasedocchkModule/addNewDocCheckListAction",
    async (value:any) => {
        const addData:any = {
            name : value.document_checklist_name.trim(),
            description: value.description.trim(),
            checklist_items: value.tempTableData
        }
        try {
            const response = await axiosRequest.post(`${EndPoint.KB_DOCUMENT_CHK_ADD_API}`, addData);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);
export const getKnowledgeBaseDocChecklistListAction = createAsyncThunk(
    "knowledgebasedocchkModule/getKnowledgeBaseDocChecklistListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.KB_DOCUMENT_CHK_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedDocumentChecklist=${value.deletedDocChecklist}` : 
        `${EndPoint.KB_DOCUMENT_CHK_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedDocumentChecklist=${value.deletedDocChecklist}`;
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const getKnowledgeBaseDocCheckListAction = createAsyncThunk(
    "knowledgebasedocchkModule/getKnowledgeBaseDocCheckListAction",
    async (value:any) => {
        const apiUrl = EndPoint.KB_DOCUMENT_CHK_GET_API+`/${value}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const editNewDocumentChkAction = createAsyncThunk(
    "knowledgebasedocchkModule/editNewDocumentChkAction",
    async (value:any) => {
        const addData:any = {
            id: value.id,
            name : value.document_checklist_name.trim(),
            description: value.description.trim(),
            checklist_items:value.tempTableData
        }
        try {
            const response = await axiosRequest.put(`${EndPoint.KB_DOCUMENT_CHK_EDIT_API}`, addData);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

export const deleteDocumentChecklistAction = createAsyncThunk(
    "knowledgebasedocchkModule/deleteDocumentChecklistAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.KB_DOCUMENT_CHK_DELETE_API}/${value.itemId}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);


/**
 * Getting ALL Checklist Action
 *
 * @type {*}
 */
export const getAllDocCheckListCommonItemAction = createAsyncThunk(
    "knowledgebasedocchkModule/getAllDocCheckListCommonItemAction",
    async () => {
        try {
            const response = await axiosRequest.get(EndPoint.KB_DOCUMENT_CHK_GETALL_API);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
)

// RESTORE Document Checklist
export const restoreDocumentChecklistAction = createAsyncThunk(
    "knowledgebasedocchkModule/restoreDocumentChecklistAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.KB_DOCUMENT_CHK_RESTORE_API}`, value);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);


const knowledgeBaseDocumentCheckSlice:any = createSlice({
    name: 'knowledgebaseDocumentCheckLists',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
            state.listObj.deletedDocChecklistProp = actions.payload.deletedDocChecklist;
        },
        startLoader(state,actions) {
            state.loading = true;
        },
        stopLoader(state,actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewDocCheckListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewDocCheckListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addNewDocCheckListAction.rejected, (state, action) => {
            state.loading = false;
            })
            .addCase(getAllDocCheckListItemAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDocCheckListItemAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllDocCheckListItemAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getKnowledgeBaseDocChecklistListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseDocChecklistListAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.knowledgeBaseDocChecklistData = data;
            })
            .addCase(getKnowledgeBaseDocChecklistListAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getKnowledgeBaseDocCheckListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseDocCheckListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getKnowledgeBaseDocCheckListAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(editNewDocumentChkAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editNewDocumentChkAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editNewDocumentChkAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(deleteDocumentChecklistAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteDocumentChecklistAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteDocumentChecklistAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(restoreDocumentChecklistAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreDocumentChecklistAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreDocumentChecklistAction.rejected, (state, action) => {
               state.loading = false;
            })
    }
});

export const knowledgeBaseDocumentCheckActions = knowledgeBaseDocumentCheckSlice.actions;

export default knowledgeBaseDocumentCheckSlice.reducer;