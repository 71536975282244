export const VALIDATION_MESSAGES = {
    EMAIL_REQUIRED: "Email address is required.",
    VALID_EMAIL: "Please enter a valid email.",
    PASSWORD_REQUIRED: "Password is required.",
    LOGIN_SUCCESS: "Login successful!",
    CHAR_MAX_10: "Max 10 characters are allowed",
    CHAR_MAX_25: "Max 25 characters are allowed",
    CHAR_MAX_50: "Max 50 characters are allowed",
    FIRST_NAME_REQUIRED: "First name is required.",
    LAST_NAME_REQUIRED: "Last name is required.",
    ROLE_REQUIRED: "Role is required.",
    DESIGNATION_EXISTS: "Designation already selected, please either select members in the previous selection or choose a different designation.",
    DEGINATION_REQUIRED: "Designation is required.",
    ACCESS_ROLE_REQUIRED: "Role is required.",
    DEPARTMENT_REQUIRED: "Department is required.",
    PHONE_NUM_LENGTH: "Phone no should have 10 characters.",
    NO_OF_CASES: "No of cases is required.",
    ATTORNEY_FORMS_NAME_REQUIRED: "Attorney name for forms is required.",
    ATTORNEY_FIRST_NAME_REQUIRED: "Attorney first name is required.",
    ATTORNEY_MIDDLE_NAME_REQUIRED: "Attorney middle name is required.",
    ATTORNEY_LAST_NAME_REQUIRED: "Attorney last name is required.",
    ROLE_NAME_INVALID:"Role must be a valid name.",
    USER_NAME_INVALID:"User must be a valid name.",
    ROLE_DISC_INVALID:"Description should not have special character's.",
    TEAM_NAME_REQUIRED: "Team name is required.",
    TEAM_NAME_MAX: "Team name must be at most 200 characters",
    MEMBER_NAME_REQUIRED: "Member name is required.",

    TEAM_NAME_INVALID:"Team name must be a valid name.",
    RECORD_REQUIRED :"Atleast one entry required to create team.",

    STAGE_NAME_REQUIRED: "Stage name is required.",
    STEP_NAME_REQUIRED: "Step name is required.",
    STEP_NAME_INVALID:"Step name must be a valid name.",
    STEP_DAYS_REQUIRED: "Step days is required.",
    STEP_NUMBER_ONLY:"Step days must be numeric value.",
    STEP_DAYS_MAX: 'Step days can not exceeds 5 digits.',

    DESIGNATION_NAME_REQUIRED: "Designation name is required.",
    DESIGNATION_NAME_INVALID:"Designation name must be a valid name.",
    DESIGNATION_NAME_MAX: "Designation name must be less than or equal to 200 characters",
    DESIGNATION_DESCRIPTION_MAX: "Description must be less than or equal to 1500 characters",
    


    TITLE_REQUIRED: "Title is required.",
    DOCUMENT_CHECKLIST_NAME: "Document checklist name is required.",
    CHECKLIST_ITEMS_REQUIRED: "Checklist items is required.",
    ALLOWED_FILE_TYPES_REQUIRED: "Allowed file types is required.",
    ROLE_PERMISSION_REQUIRED:"Atleast one permission required to create role.",
    DESCRIPTION_REQUIRED: "Description is required.",
    BLANK_NOT_ALLOW:"Blank space not allowed.",
    STAGE_NAME_INVALID:"Stage name must be a valid name.",
    DOCUMENT_NAME_REQUIRED: "Document name is required.",
    DOCUMENT_NAME_MAX: "Document name  must be at most 200 characters.",
    DESCRIPTION_MAX:"Description must be at most 1500 characters.",    
    DOCUMENT_TYPE_REQUIRED: "Document type is required.",
    DOCUMENT_FOLDER_REQUIRED: "Document folder is required.",
    STAGE_NAME_MAX: "Stage name must be less than or equal to 1000 characters",
    STAGE_DESCRIPTION_MAX: "Description must be less than or equal to 1500 characters",
    TEAMS_CHARC_LIMIT_MAX: "team_name must be at most 50 characters",
    TEAM_CHARC_LIMIT_MAX_CUSTOM: "Team name must be at most 50 characters",
    CASE_TYPE_NAME_REQUIRED: "Case type name is required.",
    FORM_NAME_REQUIRED:"Form name is required",
    LAST_REVIEWED_REQUIRED:"Last reviewed is required",
    SUBJECT_REQUIRED:"Subject is required",
    TYPE_REQUIRED:"Type is required",
    LANGUAGE_REQUIRED:"Language is required",
    FIRST_NAME_INVALID:"First name should contain only character's.",
    LAST_NAME_INVALID:"Last name should contain only character's.",
    ATTORNEY_FIRST_NAME_INVALID: "Attorney legal first name should contain only character's.",
    ATTORNEY_MIDDLE_NAME_INVALID: "Attorney legal middle name should contain only character's..",
    ATTORNEY_LAST_NAME_INVALID: "Attorney legal last name should contain only character's.",
    DOCUMENT_CHECKLIST_Title: "Document checklist item title is required.",
    DOCUMENT_CHECKLIST_Title_MAX: "Document checklist item title must be at most 150 characters.",
    DOCUMENT_CHECKLIST_INVALID: "Document checklist item title should only contain alphabets, digits and ,_- characters.",
    CLIENT_FIRST_NAME_INVALID: "Client first name should contain only character's.",
    CLIENT_MIDDLE_NAME_INVALID: "Client middle name should contain only character's..",
    CLIENT_LAST_NAME_INVALID: "Client last name should contain only character's.",
    CLIENT_MAIN_CONTACT: "Please select one value in main contact. ",
    CLIENT_DOB:"Please select date of birth.",
    STEPS_DEF_CHAR_ERR:"step_name must be at most 50 characters",
    STEP_CHAR_CUSTOM_ERR:"Step name must be less than or equal to 1000 characters",
    DROPDOWN_VALUE_SELECTION:"Please select a value from the dropdown.",
    PHONE_NUMBER_VALIDATION:"Phone numbers can contain digits only.",
    PRIMARY_EMAIL_REQUIRED:"Primary email is required.",
    PRIMARY_PHONE_REQUIRED:"Primary phone/daytime phone is required.",
    ALIEN_NUM_REQUIRED:"Alien number is required.",
    FILE_NUMBER_REQUIRED:"File number is required.",
    CASE_NUMBER_REQUIRED:"Case number is required.",
    ALIEN_NUM_MAX: 'Alien number can not exceeds 9 digits.',
    ALIEN_NUM_MIN: 'Alien number can not below 7 digits.',
    PRIMARY_PHONE_NUM_ONLY: "Primary Phone/Daytime Phone must be numeric value.",
    DOCUMENT_CHECKLIST_NAME_MAX: "Document checklist name must be at most 255 characters.",
    DOCUMENT_CHECKLIST_DESCRIPTION_MAX: "Document checklist description must be at most 1500 characters.",
    CASE_TYPE_REQUIRED:"Case type is required",
    CLIENT_BENEFICIARY_REQUIRED:"Client (Beneficiary) is required",
    SPONSOR_REQUIRED:"Sponsor is required",
    DOCUMENT_TYPE_TITLE: "Document type title is required.",
    DOCUMENT_TYPE_TITLE_MAX: "Document type title must be at most 150 characters.",
    DOCUMENT_TYPE_DESCRIPTION_MAX: "Document type description must be at most 1500 characters.",

    ROLES_TYPE_TITLE_MAX: "Role must be at most 200 characters",
    ROLES_TYPE_DESCRIPTION_MAX: "Description must be at most 1500 characters",
    TRIM_VALIDATION:"Please remove spaces from start and end of field's data",

    CASE_TYPE_NAME_MAX : "Case Type Name must be at most 200 characters",
    CASE_TYPE_DESCRIPTION_MAX : "Case Type Description must be at most 500 characters",
    CLIENT_CASE_NO_MEMBER_AVAILABLE : "No Members Assigned for this Designation",
    ALIEN_NUMBER_VALIDATION:"Alien number can be numeric with 7 to 9 digits",
    MEMBER_FIELD_TEAMS_VALIDATION:`Members field must have at least 1 item`,
    DELIVERY_DATE_VALIDATION :"Delivery date can't be before Shipping date",
    DOCUMENT_NAME_VALIDATION: "Document Name should only contain alphabets, digits and - & _ characters.",

    TO_CC_BCC_REQUIRED: "To, Cc, or Bcc any one field is required.",
}



export const API_CUSTOM_MESSAGE = {
    SOMETHING_WRONG : "Something went wrong!"
}